import { InputAutocompleteAsync } from '../../Inputs/InputAutocomplete/InputAutocompleteAsync';
import { useCallback } from 'react';
import { useLazyGetLegalEntitiesListQuery } from 'store/newApi/legalEntities/legalEntities';

export const LegalEntitiesInputAutocomplete = ({ value, ...rest }) => {
	// @ts-ignore
	const [trigger, { options = [], loading }] = useLazyGetLegalEntitiesListQuery({
		selectFromResult: ({ data }) => ({
			options: data?.results?.map((item) => ({ label: item.full_name_organization, id: item.id })),
		}),
	});

	const handleSearch = useCallback(
		(value) => {
			trigger({
				search: value,
			});
		},
		[trigger],
	);

	return (
		// @ts-ignore
		<InputAutocompleteAsync
			{...rest}
			value={value}
			fetchFn={handleSearch}
			placeholder="Выберите или введите вручную"
			options={options}
			loading={loading}
			labelText={'Юридическое лицо'}
		/>
	);
};
