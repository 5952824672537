export const modelPathMatch = [ 
	{ model: 'container', path: 'summary' },
	{ model: 'container', path: 'containers' },
	{ model: 'cargo', path: 'cargos' },
	{ model: 'order', path: 'orders' },
	{ model: 'entrypass', path: 'entries' },
	{ model: 'entrypass', path: 'historyEntries' },
	{ model: 'entryreport', path: 'inspection' },
	{ model: 'storageorders', path: 'storage' },
	{ model: 'repairorders', path: 'repair' },
	{ model: 'repackingorders', path: 'repack' },
	{ model: 'repairtasks', path: 'repairTasks' },
	{ model: 'services', path: 'tariffs' },
	{ model: 'organization', path: 'clients' },
	{ model: 'organization', path: 'transporters' },
	{ model: 'vehicle', path: 'vehicles' },
	{ model: 'customuser', path: 'empls' },
	{ model: 'group', path: 'createJob' },
	{ model: 'actionlog', path: 'tableLogs' }, 
	{ model: 'terminal', path: 'terminals' }, 
	{ model: 'terminal', path: 'legal' }, 
];
