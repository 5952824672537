import { useCallback, useEffect } from 'react';
import { useUpdateContainersMutation } from '../../../../../../store/newApi/containers/containers';
import { filterTouchedFields } from '../../../../../../newComponents/Form/utils/filterTouchedFields';

export const useHandleUpdate = ({ containerId, ...methods }) => {
	const {
		formState: { touchedFields },
	} = methods;
	const [updateContainer, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateContainerMutation }] =
		useUpdateContainersMutation();

	useEffect(() => {
		if (isUpdateSuccess) {
			resetUpdateContainerMutation();
		}
	}, [isUpdateSuccess, resetUpdateContainerMutation]);

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = {
				container_ids: [containerId],
				container_number: touchedFieldValues.containerNumber,
				orgId: touchedFieldValues.client,
				terminalId: touchedFieldValues.terminal,
				size: touchedFieldValues.size,
				type: touchedFieldValues.type,
				booking: touchedFieldValues.bookingNumber,
				is_filled: touchedFieldValues.isFilled,
				is_dangerous: touchedFieldValues.isDangerous,
				is_damaged: touchedFieldValues.isDamaged,
				line: touchedFieldValues.line,
			};
			updateContainer(payload);
		},
		[containerId, updateContainer, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
