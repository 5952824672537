import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../../newComponents/Header/Header';
import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { useInitFormState } from './hooks/useInitFormState';
import styles from './AccountEmplContainer.module.css';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { PageFooterActions } from '../../../../../shared/ui/PageFooterActions/PageFooterActions';
import { LINK_CONTAINERS_PAGE } from '../../../../../shared/containers/pageUrls';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { ContainerDetail } from './components/ContainerDetail';
import { ContainerActs } from './components/ContainerActs';

const updateSchema = z.object({
	containerNumber: z.string().optional(),
	terminal: z.number().optional(),
	client: z.number().optional(),
	size: z
		.string()
		.optional()
		.transform((val) => +val),
	type: z
		.string()
		.optional()
		.transform((val) => +val),
	line: z.number().optional(),
	bookingNumber: z.string().optional(),
	isFilled: z.boolean().optional(),
	isDamaged: z.boolean().optional(),
	isDangerous: z.boolean().optional(),
});

export const AccountEmplContainer = () => {
	const { containerId } = useParams();
	const navigate = useNavigate();
	const [optionsAct, setOptionsAct] = useState([]);

	const resolver = zodResolver(updateSchema);
	const methods = useForm({
		defaultValues: {
			line: undefined,
			containerNumber: '',
			size: undefined,
			type: undefined,
			client: undefined,
			bookingNumber: '',
			isFilled: false,
			isDangerous: false,
			isDamaged: false,
			terminal: undefined,
		},
		resolver: resolver,
	});

	const { handleUpdate } = useHandleUpdate({ ...methods, containerId });
	useInitFormState({ ...methods, id: containerId, setOptionsAct });

	const { handleSubmit } = methods;

	const onSubmit = useCallback(
		(value) => {
			handleUpdate(value);
		},
		[handleUpdate],
	);

	const handleBack = () => {
		navigate(LINK_CONTAINERS_PAGE());
	};

	return (
		<div style={{ width: '100%' }}>
			{/* @ts-ignore */}
			<Header text={`Контейнер № ${containerId}`} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<div className={styles.block}>
						<ContainerDetail {...methods} onSubmit={onSubmit} />
					</div>
					<div className={styles.block}>
						<ContainerActs options={optionsAct} />
					</div>
				</div>
				<PageFooterActions
					leftBtns={[
						{
							text: 'Назад',
							variant: 'outlined',
							color: 'primary',
							size: 'medium',
							onClick: handleBack,
						},
					]}
					rightBtns={[
						{
							text: 'Сохранить',
							variant: 'contained',
							color: 'primary',
							size: 'medium',
							onClick: handleSubmit(onSubmit),
						},
					]}
				/>
			</div>
		</div>
	);
};
