import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { CREATE_REPAIR_URL } from "shared/repairs/pageUrls";

export const CreateRepairButton = () => {
    const navigate = useNavigate();
    return (
        <Button variant="contained" color="primary" size="medium" onClick={() => navigate(CREATE_REPAIR_URL())}>
            Добавить акт
        </Button>
    )
}