export const getSelectedComponent = ({ components = [], option, part }: { components: any[], option: string, part: string }) => {
    return (
        components.find(c => c.associations?.find(a => a.option === option && `${a.part}` === part))
        || components?.find(c => !!c?.associations?.find(a => a.option === option && a.parts?.some(p => `${p}` === part)))
    )
}

export const isParamsEqualsComponent = ({ component, option, part }) => {
    return component?.associations?.find(a => a.option === option && `${a.part}` === part)
        || component?.associations?.find(a => a.option === option && a.parts?.some(p => `${p}` === part))
}

export const getComponentByCode = (components = [], code = '') => {
    return components?.find(c => c.code === code)
}

export const getComponentByName = (components = [], name = '') => {
    console.log(components)
    console.log(name)
    return components?.find(c => c.name === name)
}