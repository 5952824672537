import { Controller, useFormContext } from 'react-hook-form';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import { LineInputAutocomplete } from './LineInputAutocomplete';

export const LineInputAutocompleteController = () => {
	const { control } = useFormContext();

	return (
		<CollapseBlock title="Линия">
			<Controller name="line" control={control} render={({ field }) => <LineInputAutocomplete {...field} />} />
		</CollapseBlock>
	);
};
