import styles from '../AccountEmplContainer.module.css';
import RViewerJS from 'viewerjs-react';

type ContainerPhotoViewerProps = {
	title: string;
	images: { url: string }[];
};

export const ContainerPhotoViewer = ({ title, images }: ContainerPhotoViewerProps) => {
	return (
		<div className={styles.viewerContainer}>
			<div className={styles.viewerTitle}>{title}</div>
			{images?.length > 0 ? (
				// @ts-ignore
				<RViewerJS className={styles.viewer}>
					{images.map((image, index) => (
						<div key={`image_wrapper_${index}`} className={styles.onePhotoCard}>
							<img src={image.url} alt={'example'} />
						</div>
					))}
				</RViewerJS>
			) : (
				<div className={styles.viewerNone}>Отсутствуют</div>
			)}
		</div>
	);
};
