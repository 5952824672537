import React, { useMemo } from 'react';
import styles from './componentsStyles.module.scss';
import { isParamsEqualsComponent } from '../../../../../../../../../../shared/inspection/utils';

function UniversalBlock({ type, sideIndex, sideName, divider, part, partCode, choosen, selectedAreas = [], onClick = () => {}, selectedComponent = null }) {
	const isActive = selectedAreas.some(
		(block) => block.sideIndex === sideIndex && block.divider === divider && block.part === part && block.type === type,
	);
	const isDisabled = useMemo(() => {
		return selectedComponent && !isParamsEqualsComponent({ component: selectedComponent, option: divider, part });
	}, [selectedComponent, divider, part]);

	const handleActive = () => {
		if (isDisabled) return;
		let updatedTemporaryActiveBlocks;
		if (isActive) {
			updatedTemporaryActiveBlocks = selectedAreas.filter(
				(block) =>
					!(block.sideIndex === sideIndex && block.divider === divider && block.part === part && block.type === type),
			);
		} else {
			updatedTemporaryActiveBlocks = [...selectedAreas, { sideIndex, sideName, type, divider, part }];
		}
		onClick(updatedTemporaryActiveBlocks);
	};

	return (
		<div
			className={`${styles.mainStyle} ${styles[type]} ${isActive ? styles.active : ''} ${choosen ? styles.choosen : ''}`}
			onClick={() => handleActive()}
		>
			{choosen ? choosen : null}
		</div>
	);
}

export default UniversalBlock;
