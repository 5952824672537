import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import api from '../../api.js';

export const legalEntities = api.injectEndpoints({
	endpoints: (builder) => ({
		getLegalEntitiesList: builder.query({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/terminal/legal/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getLegalEntityById: builder.query({
			query: ({ id }) => {
				return {
					url: `/api/terminal/legal/${id}`,
					method: 'GET',
				};
			},
		}),
		createLegalEntity: builder.mutation({
			query: ({ ...payload }) => {
				return {
					url: `/api/terminal/legal/create/`,
					method: 'POST',
					body: {
						...payload,
					},
				};
			},
		}),
		updateLegalEntity: builder.mutation({
			query: ({ id, ...payload }) => {
				return {
					url: `/api/terminal/legal/update/${id}/`,
					method: 'PUT',
					body: {
						...payload,
					},
				};
			},
		}),
	}),
});

export const {
	useGetLegalEntitiesListQuery,
	useLazyGetLegalEntitiesListQuery,
	useGetLegalEntityByIdQuery,
	useCreateLegalEntityMutation,
	useUpdateLegalEntityMutation,
} = legalEntities;
