import { useCallback, useEffect, useState } from 'react';
import { useLazyGetContainerByIdQuery } from '../../../../../../../store/newApi/containers/containers';
import styles from './ChooseContainerStep.module.css';
import { ContainerInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/ContainerInputAutocomplete';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';
import { useCreateServiceOrderMutation, useUpdateRepairOrderMutation } from '../../../../../../../store/newApi/service/service';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageFooterActions } from '../../../../../../../shared/ui/PageFooterActions/PageFooterActions';
import { AssigneesInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/AssigneesInputAutocomplete';
import { filterAssignees, handleFindWorker } from '../../../../../../../shared/empls/utils';

export const ChooseContainerStep = ({
	refetchServiceOrder,
	onSetContainer,
	setServiceOrder,
	containerInfo,
	onBack,
	isEditMode,
	serviceOrderInfo,
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [fetchContainer, { container, loading: loadingClients }] = useLazyGetContainerByIdQuery({
		selectFromResult: ({ data }) => ({
			container: data,
		}),
	});

	const [updateRepairOrder, { isSuccess: isUpdateOrderSuccess }] = useUpdateRepairOrderMutation();
	const [createServiceOrder, { data, isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateServiceOrder }] =
		useCreateServiceOrderMutation();

	useEffect(() => {
		if (container) onSetContainer(container);
	}, [container, onSetContainer]);

	const handleContainerChoose = useCallback(
		(id) => {
			fetchContainer({ id });
		},
		[fetchContainer],
	);

	const [managerAssignee, setManagerAssignee] = useState('');
	const [managerRepairAssignee, setManagerRepairAssignee] = useState('');

	useEffect(() => {
		if (serviceOrderInfo) {
			setManagerAssignee(handleFindWorker({ assignees: serviceOrderInfo?.assignees, type: 0, item: 'worker' }));
			setManagerRepairAssignee(handleFindWorker({ assignees: serviceOrderInfo?.assignees, type: 1, item: 'worker' }));
		}
	}, [serviceOrderInfo]);

	
	const handleCreate = useCallback(() => {
		const assignees = [];

		if (managerAssignee) {
			assignees.push({
				worker: managerAssignee,
				type: 0,
			});
		}

		if (managerRepairAssignee) {
			assignees.push({
				worker: managerRepairAssignee,
				type: 1,
			});
		}

		createServiceOrder({
			container: container.id,
			set_assignee: assignees,
		});
	}, [managerAssignee, managerRepairAssignee, container, createServiceOrder]);

	const handleUpdate = useCallback(() => {
		const assigneesToUpdate = [];

		if (managerAssignee && managerAssignee !== handleFindWorker({ assignees: serviceOrderInfo?.assignees, type: 0, item: 'worker' })) {
			assigneesToUpdate.push({
				worker: managerAssignee,
				type: 0,
			});
		}

		if (
			managerRepairAssignee &&
			managerRepairAssignee !== handleFindWorker({ assignees: serviceOrderInfo?.assignees, type: 1, item: 'worker' })
		) {
			assigneesToUpdate.push({
				worker: managerRepairAssignee,
				type: 1,
			});
		}

		if (assigneesToUpdate.length > 0) {
			updateRepairOrder({
				id: serviceOrderInfo.id,
				set_assignee: assigneesToUpdate,
			});
		}
	}, [managerAssignee, managerRepairAssignee, serviceOrderInfo, updateRepairOrder]);


	const handleNext = useCallback(() => {
		if (!isEditMode) {
			handleCreate();
		} else {
			handleUpdate();
		}
	}, [isEditMode, handleCreate, handleUpdate]); 

	useEffect(() => {
		if (isUpdateOrderSuccess) {
			refetchServiceOrder();
		}
	}, [isUpdateOrderSuccess]);

	useEffect(() => {
		if (isCreateSuccess && data) {
			setServiceOrder(data);
			resetCreateServiceOrder();
			navigate(`${location.pathname}/${data.id}?step=1`);
		}
	}, [isCreateSuccess, resetCreateServiceOrder, setServiceOrder, data, navigate, location]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<div className={styles.containerFormTemplate}>
				<div class={styles.item}>
					{!isEditMode && (
						<ContainerInputAutocomplete
							value={containerInfo?.id}
							onChange={handleContainerChoose}
							searchParams={{ repair_order_available: true }}
							labelText="Контейнер"
						/>
					)}
					{isEditMode && <InputText labelText="Контейнер" disabled value={containerInfo?.container_number} />}
				</div>
				{containerInfo && (
					<>
						<InputText labelText="Тип" disabled value={containerInfo.type_display} />
						<InputText labelText="Размер" disabled value={containerInfo.size_display} />
						<div class={styles.item}>
							<InputText labelText="Клиент" disabled value={containerInfo.client_display} />
						</div>
						<div class={styles.item}>
							<InputText labelText="Терминал" disabled value={containerInfo.terminal_display} />
						</div>

						{containerInfo?.terminal && <>
							<div class={styles.item}>
								<AssigneesInputAutocomplete
									value={managerAssignee}
									valueName={
										serviceOrderInfo?.assignees
											? handleFindWorker({ assignees: serviceOrderInfo?.assignees, type: 0, item: 'assignee_name' })
											: ''
									}
									onChange={(value) => setManagerAssignee(value)}
									assigneesHistory={
										serviceOrderInfo?.assignees ? filterAssignees({ assignees: serviceOrderInfo?.assignees, type: 0 }) : []
									}
									group={3}
									terminal={containerInfo?.terminal}
									labelText={'Ответственный менеджер'}
								/>
							</div>
							<div class={styles.item}>
								<AssigneesInputAutocomplete
									value={managerRepairAssignee}
									valueName={
										serviceOrderInfo?.assignees
											? handleFindWorker({ assignees: serviceOrderInfo?.assignees, type: 1, item: 'assignee_name' })
											: ''
									}
									onChange={(value) => setManagerRepairAssignee(value)}
									assigneesHistory={
										serviceOrderInfo?.assignees ? filterAssignees({ assignees: serviceOrderInfo?.assignees, type: 1 }) : []
									}
									group={4}
									terminal={containerInfo?.terminal}
									labelText={'Ответственный бригадир'}
								/>
							</div>
						</>}
					</>
				)}
			</div>
			<PageFooterActions
				leftBtns={[
					{
						text: 'Назад',
						variant: 'outlined',
						color: 'primary',
						size: 'medium',
						onClick: onBack,
					},
				]}
				rightBtns={[
					{
						text: isEditMode ? 'Сохранить' : 'Продолжить',
						variant: 'contained',
						color: 'primary',
						size: 'medium',
						disabled: !containerInfo,
						onClick: handleNext,
					},
				]}
			/>
		</div>
	);
};
