import { useEffect } from 'react';
import { handleFindWorker } from '../../../../../../../../shared/empls/utils';

export const useInitFormState = ({ data, ...methods }) => {
	const { setValue } = methods;

	useEffect(() => {
		if (data) {
			if (data?.full_name) setValue('full_name', data.full_name);
			if (data?.password) setValue('password', data.password);
			if (data?.organization_name) setValue('organization_name', data.organization_name);
			if (data?.contact_email) setValue('email', data.contact_email);
			if (data?.inn) setValue('inn', data.inn);
			if (data?.organization_address) setValue('organization_address', data.organization_address);
			setValue('isCan_confirm_repair', data.can_confirm_repair);
			setValue('isCan_see_storage_price', data.can_see_storage_price);
			if (data?.terminals) {
				setValue('terminals', data.terminals.map(terminal => terminal.id));
			}
			if (data?.assignees) { 
				setValue('assignee', handleFindWorker({ assignees: data?.assignees, type: 0, item: 'worker' }));
			}
			if (data?.legal_entities) setValue('legalEntity', data.legal_entities?.id);
		}
	}, [data, setValue]);
};
