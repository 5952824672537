import { Box, Container, Grid, Typography } from '@mui/material';
import { useLazyGetOrdersListQuery } from 'store/newApi/orders/orders';
import { Preloader } from 'components';
import { OrderCard } from 'entities/order/ui/OrderCard/OrderCard';
import { ORDER_STATUS } from 'shared/orders/constants';
import { ORDER_INFO_URL } from 'shared/orders/pageUrls';
import { useInfiniteScrollFetching } from 'shared/hooks/useInfiniteScrollFetching';

import { Link } from 'react-router-dom';

import styles from 'shared/styles.module.scss';

export const OrdersList = () => {
	const { containerRef, renderedItems, isLoading, isFetching } = useInfiniteScrollFetching({
		useMutation: useLazyGetOrdersListQuery,
		filters: {
			worker_list_only: true
		},
	});

	return (
		<div className={styles.entity_list} style={{ marginBottom: '30px' }}>
			<Container ref={containerRef} className={styles.entity__collection} sx={{
                    padding: '8px',
                    maxHeight: '100%',
                    overflowY: 'auto',
                }
            }>
				{isLoading ? (
					<Box>
						{/* @ts-ignore */}
						<Preloader />
					</Box>
				) : (
					<Grid container sx={{ gap: '10px', justifyContent: 'start', flexWrap: 'wrap', position: 'relative' }}>
						{renderedItems.length === 0 && <Typography variant="subtitle1">Нет прикрепленных заявок</Typography>}
						{renderedItems?.map((card, index) => (
							<Grid key={index} className={styles.entity_item}>
								<Link to={ORDER_INFO_URL(card?.terminal?.id, card?.id)}>
									<OrderCard
										orderId={card?.id}
										status={ORDER_STATUS[card?.status]}
										colorStatus={card?.status == 3 ? 'success' : 'primary'}
										client={card?.client?.organization_name}
									/>
								</Link>
							</Grid>
						))}
					</Grid>
				)}
			</Container>
		</div>
	);
};
