import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateLegalEntityMutation } from 'store/newApi/legalEntities/legalEntities';

export const useHandleCreate = ({ ...methods }) => {
	const navigate = useNavigate();
	const { reset: resetForm } = methods;
	const [createLegalEntity, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, data: dataResponse, reset: resetCreateLegalEntityMutation }] =
		useCreateLegalEntityMutation();

	useEffect(() => {
		if (isCreateSuccess) {
			resetCreateLegalEntityMutation();
			navigate(`/accountEmpl/legal/${dataResponse.id}`)
		}

		return () => {
			resetForm();
		};
	}, [isCreateSuccess, resetCreateLegalEntityMutation, resetForm]);

	const handleCreate = useCallback(
		(value) => {
			createLegalEntity({ ...value });
		},
		[createLegalEntity],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
	};
};
