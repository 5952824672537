import BtnDropdown from "newComponents/BtnDropdown/BtnDropdown";
import { useNavigate } from "react-router-dom";
import { CREATE_ORDER_DELIVERY_URL, CREATE_ORDER_RECIEV_URL } from "shared/orders/pageUrls";

export const CreateOrderButton = () => {
    const navigate = useNavigate();
    const menuItems = [
		{
			text: 'Приём контейнеров',
			onClick: () => {
				navigate(CREATE_ORDER_RECIEV_URL);
			},
		},
		{
			text: 'Выдача контейнеров',
			onClick: () => {
				navigate(CREATE_ORDER_DELIVERY_URL);
			},
		},
	];

    return <BtnDropdown mainButtonText="Добавить заявку" dropdownItems={menuItems} />
}