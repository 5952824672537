import { Button, useMediaQuery } from '@mui/material';
import styles from './PageTabs.module.css';
import { useRef } from 'react';

type PageTab = {
    title: string;
    step: number;
    disabled?: boolean;
}

export type PageTabsProps = {
    tabs: PageTab[];
    currentStep: number;
    hidden?: boolean;
    rightActions?: React.ReactNode | null;
    onTabClick: (step: number) => void;
}

export const PageTabs = ({ tabs, currentStep, onTabClick, hidden = false, rightActions = null }: PageTabsProps) => {
    // @ts-ignore
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
    const ref = useRef(null);

    if (!tabs || hidden) return null;
    return (
        <div className={styles.tabsContainer} ref={ref}>
            <div style={{ display: 'flex', gap: '8px' }}>
                {tabs?.map(t => {
                    return <Button
                        variant="contained"
                        color={currentStep === t.step ? 'secondary' : 'primary'}
                        size={isDesktop ? "medium" : "small"}
                        disabled={t.disabled || false}
                        onClick={() => onTabClick(t.step)}
                    >
                        {t.title}
                    </Button>
                })}
            </div>
            <div>
                {rightActions}
            </div>
        </div>
    )
}