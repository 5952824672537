import { Autocomplete, Box, InputAdornment } from "@mui/material";
import InputText from "../InputText/InputText";
import { useState } from "react";
import { ArrowForwardIos } from "@mui/icons-material";

export const InputAutocomplete = ({ options, placeholder, onChange, value, labelText, rightActionButton = null, ...rest }) => {
    const [opened, setOpened] = useState(false);
    const handleOpen = (event) => {
        setOpened(true);
    };

    const handleClose = () => {
        setOpened(false);
    };

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2px' }}>
            {labelText && <span>{labelText}</span>}
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Autocomplete
                    {...rest}
                    disablePortal
                    sx={(theme) => ({
                        display: 'inline-block',
                        flexGrow: 1,
                        '& .MuiAutocomplete-inputRoot': {
                            height: '100%',
                            width: '100%',
                            padding: 0,
                            paddingRight: '0 !important',
                            color: '#000'
                        },
                    })}
                    onChange={(event, newValue) => {
                        onChange(newValue?.id);
                    }}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    options={options}
                    noOptionsText="Не найдено"
                    value={options.find(option => option.id === value) || null}
                    getOptionLabel={(option) => option.label}
                    placeholder={placeholder}
                    renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                            <Box
                                key={key}
                                component="li"
                                sx={{ mr: 2, flexShrink: 0, color: '#000' }}
                                {...optionProps}
                            >
                                {option.label}
                            </Box>
                        );
                    }}
                    renderInput={(params) => {
                        return (
                            <div ref={params.InputProps.ref} style={{ position: 'relative' }}>
                                <InputText
                                    {...params}
                                    value={value}
                                    placeholder={placeholder}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ArrowForwardIos
                                                    style={{
                                                        width: 16,
                                                        height: 16,
                                                        color: (theme) => theme.palette.text.secondary,
                                                        transform: opened ? 'rotate(90deg)' : 'rotate(0deg)',
                                                        transition: 'transform 0.3s ease',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        )
                    }
                    }
                />
                {rightActionButton}
            </div>
        </div>
    )
}