import { Box, Container, Grid, Typography } from '@mui/material';
import { Preloader } from 'components';
import { ORDER_INFO_URL } from 'shared/orders/pageUrls';
import { useInfiniteScrollFetching } from 'shared/hooks/useInfiniteScrollFetching';

import { Link } from 'react-router-dom';

import styles from 'shared/styles.module.scss';
import { useLazyGetEntryreportsListQuery } from 'store/newApi/entryreports/entryreports';
import { InspectionActCard } from '../InspectionActCard/InspectionActCard';
import { INSPECTION_INFO_URL } from 'shared/inspection/pageUrls';

export const InspectionList = () => {
	const { containerRef, renderedItems, isLoading, isFetching } = useInfiniteScrollFetching({
		useMutation: useLazyGetEntryreportsListQuery,
		filters: {
			worker_list_only: true
		},
	});

	return (
		<div className={styles.entity_list} style={{ marginBottom: '30px' }}>
			<Container ref={containerRef} className={styles.entity__collection} sx={{
                    padding: '8px',
                    maxHeight: '100%',
                    overflowY: 'auto',
                }
            }>
				{isLoading ? (
					<Box>
						{/* @ts-ignore */}
						<Preloader />
					</Box>
				) : (
					<Grid container sx={{ gap: '10px', justifyContent: 'start', flexWrap: 'wrap', position: 'relative' }}>
						{renderedItems.length === 0 && <Typography variant="subtitle1">Нет прикрепленных актов</Typography>}
						{renderedItems?.map((card, index) => (
							<Grid key={index} className={styles.entity_item}>
								<Link to={INSPECTION_INFO_URL(card?.id, 0)}>
									<InspectionActCard
										id={card?.id}
										status={card?.status_display}
										colorStatus={'primary'}
										client={card?.client_display}
										containerNumber={card?.container_number_display}
									/>
								</Link>
							</Grid>
						))}
					</Grid>
				)}
			</Container>
		</div>
	);
};
