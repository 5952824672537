import { InputAutocomplete } from '../../Inputs/InputAutocomplete/InputAutocomplete';
import { useGetLinesListQuery } from '../../../store/newApi/containers/containers';

export const LineInputAutocomplete = ({ ...rest }) => {
	const { linesOptions = [] } = useGetLinesListQuery(
		{},
		{
			selectFromResult: ({ data }) => ({
				linesOptions: data?.map((t) => ({ label: t.line_name, id: t.id })),
			}),
		},
	);

	return <InputAutocomplete {...rest} placeholder="Выберите или введите вручную" options={linesOptions} />;
};
