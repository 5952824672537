import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box } from '@mui/material'; 
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Preloader from '../../../../components/Preloader/Preloader';
import { useLazyGetEntryreportsListQuery } from '../../../../store/newApi/entryreports/entryreports.js';
import { formatInitialDate } from '../../../../helpers/formatDate.js';
import BtnDropdown from '../../../../newComponents/BtnDropdown/BtnDropdown.jsx';
 
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/entryreports.js';
import { changepostEntryreports_success } from '../../../../store/slices/containerAcceptanceSlice.js';
import { CREATE_INSPECTION_DELIVERY_URL, CREATE_INSPECTION_RECIEV_URL, INSPECTION_INFO_URL } from '../../../../shared/inspection/pageUrls'; 
import { InspectionActCard } from '../../../../entities/inspection/ui/InspectionActCard/InspectionActCard';
import { Filters } from '../../../../shared/ui/Filters/Filters';
import { isEmpl } from '../../../../shared/auth/isEmpl';

function AccountEmplInspection() {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state['entryreportsRtk']?.filter) ?? {};
	const [filtering, setFiltering] = useState(false);
	const [trigger, { data, isLoading }] = useLazyGetEntryreportsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ ...filters, page: 1, page_size: 30 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 30, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 30 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	const menuItems = [
		{
			text: 'Приём контейнера',
			onClick: () => {
				navigate(CREATE_INSPECTION_RECIEV_URL);
				dispatch(changepostEntryreports_success(false));
			},
		},
		{
			text: 'Выдача контейнера',
			onClick: () => {
				navigate(CREATE_INSPECTION_DELIVERY_URL);
			},
		},
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const isButtonCreate = useMemo(() => isEmpl() ? <BtnDropdown mainButtonText="Добавить акт" dropdownItems={menuItems} /> : null, [isEmpl()]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Акты осмотра'}
				btnDesktop={[isButtonCreate]}
				btnMobile={[isButtonCreate]}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'status',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: 'Черновик' },
									{ value: 2, label: 'Подтвержден' },
								],
							},
							{
								name: 'type',
								title: 'Тип',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: 'Прием' },
									{ value: 2, label: 'Вывоз' },
								],
							},
							{
								name: ['updated_at_after', 'updated_at_before'],
								title: 'Дата осмотра',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				}
			/>

			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index} onClick={() => navigate(INSPECTION_INFO_URL(card?.id, 0))}>
									<InspectionActCard
										id={card?.id}
										status={card?.status_display}
										colorStatus={'primary'}
										client={card?.client_display}
										type={card?.type_display}
										dateCreated={card?.created_at ? formatInitialDate(card?.created_at) : '-'}
										containerNumber={card?.container_number_display}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplInspection;
