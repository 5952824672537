import { useEffect, useMemo } from 'react'; 
import { useGetStorageByIdQuery } from '../../../../store/newApi/service/service';

export const useInitFormState = ({ ids, isReady, ...methods }) => {
	const { setValue } = methods;
	const isUpdate = useMemo(() => ids.length > 0, [ids]);
	const { data } = useGetStorageByIdQuery(
		{ id: ids[0] },
		{ 
			skip: !isUpdate,
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		if (data?.results[0] && isReady && isUpdate) { 
			if (data?.results[0]?.free_days_storage) setValue('free_days_storage', `${data?.results[0]?.free_days_storage}`);
			if (data?.results[0]?.total_price) setValue('total_price', `${data?.results[0]?.total_price}`);
			if (data?.results[0]?.date_from) setValue('date_from', data?.results[0]?.date_from);
			if (data?.results[0]?.date_to) setValue('date_to', data?.results[0]?.date_to);
		}
	}, [data, setValue, isReady, isUpdate]);
};
