import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { Button } from '@mui/material';
import { getDefaultPermissions, getPermissionsMap, getUserTerminalGroup } from './utils'; 
import { getSelectedPermissionIds } from './utils/getSelectedPermissionIds'; 
import { useLazyGetPermissionsListQuery } from '../../../../../../../store/newApi/permissions/permissions';
import { JobInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/JobInputAutocomplete';
import { useUpdateStaffMutation } from '../../../../../../../store/newApi/users/users';
import { useHandleCreate } from '../hooks/useHandleCreate';
import { PermissionsTable } from '../../../../../../../newComponents/PermissionsTable/PermissionsTable';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';

export const TabTerminalRights = memo(({ data, terminal, onBack, value = {}, successCallback }) => {
	const { showNotificaton } = useNotify();
	const [selectedItems, setSelectedItems] = useState({});
	const [selectedJob, setSelectedJob] = useState(null);
	const [fetchPermissionsList, { data: permissionsList }] = useLazyGetPermissionsListQuery({});
	const [updateStaff] = useUpdateStaffMutation();
	const { handleCreate, isCreateLoading, } = useHandleCreate({ successCallback });
	const terminalExists = useMemo(() => data?.terminals?.some((t) => t.id === terminal), [data, terminal]);
	const currentUserTerminal = useMemo(() => data?.terminals?.find((t) => t.id === terminal), [data, terminal]);

	const personalPermissions = useMemo(() => currentUserTerminal ? currentUserTerminal.permissions : [], [currentUserTerminal])
	// const userTerminalGroupPermissions = useMemo(() => currentUserTerminal ? currentUserTerminal.group.permissions : [], [currentUserTerminal]);

	useEffect(() => {
		fetchPermissionsList({});
	}, []); 

	useEffect(() => {
		if (!selectedJob && terminalExists) {
			setSelectedJob(getUserTerminalGroup(data?.terminals || [], terminal))
		}
	}, [data, terminal, terminalExists]);

	const allSections = useMemo(() => permissionsList?.map((obj) => obj.name), [permissionsList])

	// Неизменяемые чекбоксы пермишенов группы
	const defaultPermissionsSelections = useMemo(() => getDefaultPermissions(currentUserTerminal, selectedJob), [currentUserTerminal, selectedJob])

	// Чекбоксы пермишенов сотрудника
	const selectedCheckboxes = useMemo(() => ({ defaultPermissionsSelections, selectedItems }), [defaultPermissionsSelections, selectedItems]);

	useEffect(() => {
		setSelectedItems(getPermissionsMap(personalPermissions));
	}, [personalPermissions, allSections]);

	const handleUpdatePermissions = () => {
		if (data) {
			let requestData = {};

			if (selectedJob) {
				requestData = {
					full_name: data?.terminals?.full_name,
					username: data?.terminals?.username,
					staff_terminal: [
						{
							terminal: terminal,
							group: selectedJob.id,
							permissions: getSelectedPermissionIds(selectedItems.permissionsMap, permissionsList),
						},
					],
				};
			} else {
				requestData = {
					full_name: data?.terminals?.full_name,
					username: data?.terminals?.username,
					staff_terminal: [
						{
							terminal: terminal,
							group: data.terminals[terminal - 1].group.id,
							permissions: getSelectedPermissionIds(selectedItems.permissionsMap, permissionsList),
						},
					],
				};
			}

			updateStaff({ userId: data?.id, ...requestData })
				.then(() => showNotificaton({ type: 'success', message: 'Данные обновлены' }));
		} else {
			let requestData = {};
			if (selectedJob) {
				requestData = {
					group: selectedJob.id,
				};
			}
			requestData['terminal'] = terminal;
			requestData['permissions'] = getSelectedPermissionIds(selectedItems.permissionsMap, permissionsList);
			  
			handleCreate({ value, staff_terminal: [requestData] });
		}
	};

	const handleChoose = useCallback((id, jobOption) => {
		setSelectedJob(jobOption);
	}, []);

	const handleSelectItems = useCallback((items) => {
		setSelectedItems({ permissionsMap: items, disabled: false });
	}, []);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			{terminalExists ? (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
					<JobInputAutocomplete value={selectedJob?.id} onChange={handleChoose} />
					{permissionsList && (
						<PermissionsTable
							sections={allSections}
							selectedItems={selectedCheckboxes}
							setSelectedItems={handleSelectItems}
						/>
					)}
				</div>
			) : (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
					<JobInputAutocomplete value={selectedJob?.id} onChange={handleChoose} />
					{permissionsList && (
						<PermissionsTable
							sections={allSections}
							selectedItems={selectedCheckboxes}
							setSelectedItems={handleSelectItems}
						/>
					)}
				</div>
			)}
			<div style={{ justifyContent: 'flex-end', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
					Назад
				</Button>
				<Button variant="contained" color="primary" size="medium" onClick={handleUpdatePermissions}>
					Сохранить
				</Button>
			</div>
		</div>
	);
});
