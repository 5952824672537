import api from '../../api.js';
 import { buildQueryString } from '../../../shared/ui/Filters/utils.js';

export const entryreports = api.injectEndpoints({
	endpoints: (builder) => ({
		getEntryreportsList: builder.query({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/entryreports/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		createEntryReport: builder.mutation({
			query: ({ containerId, type, ...payload }) => {
				return {
					url: `/api/entryreports/create/`,
					method: 'POST',
					body: {
						container: containerId,
						type,
						...payload
					},
				}
			}
		}),
		updateEntryReport: builder.mutation({
			query: ({ id, ...payload }) => {
				const validatedContainerPayload = Object.entries(payload)
					.filter(([key, value]) => value !== "" && value !== undefined)
					.reduce((result, [key, value]) => ({ ...result, [key]: value }), {})
				return {
					url: `/api/entryreports/${id}/update/`,
					method: 'PUT',
					body: {
						...validatedContainerPayload
					},
				}
			}
		}),
		getEntryReportById: builder.query({
			query: ({ id }) => {
				return {
					url: `/api/entryreports/${id}/`,
					method: 'GET',
				}
			}
		}),
	}),
});

export const {
	useGetEntryreportsListQuery,
	useLazyGetEntryreportsListQuery,
	useLazyGetEntryReportByIdQuery,
	useCreateEntryReportMutation,
	useUpdateEntryReportMutation
} = entryreports;
