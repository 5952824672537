import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material'; 
import { useLazyGetEntriesListQuery } from '../../../../store/newApi/entries/entries';
import { Link, useNavigate } from 'react-router-dom';
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Preloader from '../../../../components/Preloader/Preloader'; 
import { formatInitialDate } from '../../../../helpers/formatDate.js';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/entries.js';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { isEmpl } from '../../../../shared/auth/isEmpl';
import { isClient } from '../../../../shared/auth/isClient'; 
import { EntryCard } from '../../../../entities/entries/ui/EntryCard/EntryCard';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplEntriesList() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const filters = useSelector((state) => state['entriesRtk']?.filter) ?? {};
	const [trigger, { data, isLoading }] = useLazyGetEntriesListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const [filtering, setFiltering] = useState(false);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ ...filters, page: 1, page_size: 30 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 30, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 30 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Список пропусков'}
				btnDesktop={[]}
				btnMobile={[]}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'status',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 0, label: 'Создан' },
									{ value: 1, label: 'Подтвержден' },
									{ value: 2, label: 'Активирован' },
									{ value: 3, label: 'Отменён' },
									{ value: 4, label: 'Контейнер и пропуск удалены' },
								],
							},
							{
								name: 'terminal_id',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: ['created_at_from', 'created_at_to'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['chekin_at_from', 'chekin_at_to'],
								title: 'Дата приезда',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['eta_checkin_from', 'eta_checkin_to'],
								title: 'Ожидаемая дата выезда',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				} 
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index}>
									<Link
										to={`/${isEmpl() ? 'accountEmpl' : isClient() ? 'accountClient' : ''}/entry/${card?.terminal?.id}/${card?.order}/${card.id}`}
									>
										<EntryCard
											name={card?.name}
											status={card?.status_display}
											colorStatus={card?.status == 1 ? 'success' : 'primary'}
											client={card?.container?.client_display}
											transporter={card?.transporter?.organization_name}
											order={card?.order}
											terminal={card?.terminal?.name}
											dateCheckin={card?.eta_checkin ? formatInitialDate(card?.eta_checkin) : '-'}
											driver={card?.vehicle_driver}
											containerNumber={card?.container?.container_number}
											vehicle={card?.vehicle_display}
											trailerNumber={card?.vehicle_trailer_number}
											sealNumber={card?.seal_number}
										/>
									</Link>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplEntriesList;
