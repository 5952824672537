import { useCallback, useEffect } from 'react'; 
import { filterTouchedFields } from '../../../../../../../newComponents/Form/utils/filterTouchedFields';
import { useUpdateStaffMutation } from '../../../../../../../store/newApi/users/users';

export const useHandleUpdate = ({ ...methods }) => {
	const {
		formState: { touchedFields },
		reset: resetForm,
	} = methods;
	const [updateStaff, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading }] = useUpdateStaffMutation();

	useEffect(() => {
		return () => {
			resetForm();
		};
	}, []);

	const handleUpdate = useCallback(
		({ userId, value }) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = {
				full_name: touchedFieldValues.full_name,
				password: touchedFieldValues.password,
				username: touchedFieldValues.username, 
				email: touchedFieldValues.email,
				phone: touchedFieldValues.phone, 
			};
			updateStaff({
				userId: userId,
				...payload,
			});
		},
		[updateStaff, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
