import { FilterAssigneesType, FindWorkerType } from './types';

// Нахождение данных назначенных ответсвенных
export const handleFindWorker = ({ assignees, type, item }: FindWorkerType) => {
	const assignee = assignees.find((e) => e.type === type && e.disabled_at === null);
	return assignee ? assignee[item] : null;
};

// Нахождение данных ответсвенных для истории
export const filterAssignees = ({ assignees, type }: FilterAssigneesType) => {
	return assignees
		.filter((e) => e.type === type && e.disabled_at !== null)
		.map((e) => ({
			assignee_name: e.assignee_name,
			type_display: e.type_display,
			created_at: e.created_at,
			disabled_at: e.disabled_at,
		}));
};
