import { useEffect } from 'react';
import { useGetLegalEntityByIdQuery } from 'store/newApi/legalEntities/legalEntities';

export const useInitFormState = ({ legalEntityId, ...methods }) => {
	const { setValue } = methods;

	const { data } = useGetLegalEntityByIdQuery(
		{ id: legalEntityId },
		{
			skip: !legalEntityId,
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		if (data) {
			setValue('full_name_organization', data?.full_name_organization);
			setValue('eng_full_name_organization', data?.eng_full_name_organization);
			setValue('inn', data?.inn);
			setValue('kpp', data?.kpp);
			setValue('ogrn', data?.ogrn);
			setValue('legal_address', data?.legal_address);
			setValue('actual_address', data?.actual_address);
			setValue('contact_number', data?.contact_number);
			setValue('direct_name', data?.direct_name);
		}
	}, [data, setValue]);
};
