import { Alert, AlertTitle, IconButton } from '@mui/material';
import { createContext, useContext, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';

export type NotifyType = 'success' | 'info' | 'warning' | 'error';

type Notify = {
	type: NotifyType;
	message: string;
	title?: string;
};

type AlertContextType = {
	showNotificaton: (values: Notify) => void;
};

export const AlertContext = createContext<AlertContextType>({
	showNotificaton: () => {},
});

const NotifyAlert = ({ title, message = '', type, onClose }) => {
	return (
		<Alert
            variant="filled"
			severity={type}
			action={
				<IconButton
					aria-label="close"
					color="inherit"
					size="small"
					onClick={onClose}
				>
					<CloseIcon fontSize="inherit" />
				</IconButton>
			}
		>
			{title && <AlertTitle>{title}</AlertTitle>}
			{message}
		</Alert>
	);
};

export const AlertProvider = ({ children }) => {
	const [notify, setNotify] = useState<Notify | null>(null);
	const timeoutRef = useRef(null);

	const handleResetNotify = () => {
		setNotify(null);
	};

	const handleShowNotification = (value: Notify) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
		setNotify(value);
		timeoutRef.current = setTimeout(() => {
			handleResetNotify();
		}, 6000);
	};

	return (
		<AlertContext.Provider value={{ showNotificaton: handleShowNotification }}>
			{children}
			{createPortal(
				<div style={{ position: 'absolute', top: 0, right: 0, padding: '10px' }}>
					{notify && (
						<NotifyAlert title={notify.title} message={notify.message} type={notify.type} onClose={handleResetNotify} />
					)}
				</div>,
				document.body,
			)}
		</AlertContext.Provider>
	);
};

export const useNotify = () => {
    return useContext(AlertContext);
}