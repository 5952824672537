import { useCallback, useEffect } from 'react';
import { useCreateStorageMutation } from '../../../../store/newApi/service/service';
import { formatISO } from '../../../../helpers/formatDate';

export const useHandleCreate = ({ handleClose, setShowNotification, refetchData, ...methods }) => {
	const { reset: resetForm } = methods;
	const [
		createStorage,
		{ isSuccess: isCreateSuccess, isError: isCreateError, isLoading: isCreateLoading, reset: resetCreateStorageMutation },
	] = useCreateStorageMutation();

	useEffect(() => {
		if (isCreateSuccess) {
			handleClose();
			resetCreateStorageMutation();
			resetForm();
			refetchData();
			// TODO: Вызвать нотификашку
		} 

		return () => {
			resetForm();
		};
	}, [isCreateSuccess, handleClose, resetCreateStorageMutation, resetForm, refetchData]);

	useEffect(() => {
		if (isCreateError) {
			setShowNotification(true); 
			resetCreateStorageMutation()
		} 
	}, [isCreateError, setShowNotification, resetCreateStorageMutation]);

	const handleCreate = useCallback(
		(value) => {
			createStorage({
				orgId: value.client,
				container: value.containerNumber,
				free_days_storage: value.free_days_storage,
				client_tariff: value.client_tariff,
				date_from: value.date_from && formatISO(value.date_from, 'yyyy-MM-dd'),
				date_to: value.date_to && formatISO(value.date_to, 'yyyy-MM-dd'),
			});
		},
		[createStorage],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
		isCreateError,
	};
};
