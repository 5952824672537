import { Controller } from 'react-hook-form';
import { FormWrapper } from '../../../../../../newComponents/Form/FormWrapper';
import styles from '../AccountEmplContainer.module.css';
import { TerminalInputAutocomplete } from '../../../../../../newComponents/Form/Fields/TerminalInputAutocomplete';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { isClient } from '../../../../../../shared/auth/isClient';
import { ClientInputAutocomplete } from '../../../../../../newComponents/Form/Fields/ClientInputAutocomplete';
import InputText from '../../../../../../newComponents/Inputs/InputText/InputText';
import { LineInputAutocomplete } from '../../../../../../newComponents/Form/Fields/LineInputAutocomplete';
import { CONTAINER_SIZE, CONTAINER_TYPE } from '../../../../../../shared/containers/constants';
import { Switcher } from '../../../../../../components';
import { CollapseBlock } from '../../../../../../newComponents/CollapseBlock/CollapseBlock';

type ContainerDetailProps = {
	onSubmit: (value: any) => void;
};

export const ContainerDetail = ({ onSubmit, ...methods }: ContainerDetailProps) => {
	const {
		// @ts-ignore
		control, watch, setValue, formState: { errors },
	} = methods;

	const isFilled = watch('isFilled');
	const isDangerous = watch('isDangerous');
	const isDamaged = watch('isDamaged');

	return (
		<FormWrapper onSubmit={onSubmit} {...methods}>
			<div className={styles.form}>
				<Controller
					name="terminal"
					control={control}
					render={({ field }) => (
						<>
							<TerminalInputAutocomplete {...field} labelText="Терминал" placeholder="Выберите или введите вручную" />
							{errors?.terminal && (
								<Typography color="error" variant="caption">
									{errors?.terminal?.message}
								</Typography>
							)}
						</>
					)}
				/>
				{!isClient() && (
					<Controller
						name="client"
						control={control}
						render={({ field }) => (
							<>
								<ClientInputAutocomplete {...field} labelText="Клиент" />
								{errors?.client && (
									<Typography color="error" variant="caption">
										{errors?.client?.message}
									</Typography>
								)}
							</>
						)}
					/>
				)}
				<Controller
					name="containerNumber"
					control={control}
					render={({ field }) => (
						<> 
							{/* @ts-ignore  */}
							<InputText {...field} placeholder="Введите номер" labelText="Номер контейнера" />
							{errors?.containerNumber && (
								<Typography color="error" variant="caption">
									{errors?.containerNumber?.message}
								</Typography>
							)}
						</>
					)}
				/>
				<Controller
					name="bookingNumber"
					control={control}
					//  @ts-ignore
					render={({ field }) => <InputText {...field} placeholder="Введите номер" labelText="Букинг" />}
				/>

				<div>
					<Controller
						name="line"
						control={control}
						render={({ field }) => (
							<>
								<LineInputAutocomplete {...field} labelText="Линия" placeholder="Выберите" />
								{errors?.line && (
									<Typography color="error" variant="caption">
										{errors?.line?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</div>
			</div>
			<div className={styles.formCol}>
				<CollapseBlock title="Тип" defaultOpen={false}>
					<Controller
						name="type"
						control={control}
						render={({ field }) => (
							<RadioGroup {...field} value={field.value || ''}>
								{Object.values(CONTAINER_TYPE).map((type) => (
									<FormControlLabel key={type.id} value={type.id} control={<Radio />} label={type.value} />
								))}
							</RadioGroup>
						)}
					/>
				</CollapseBlock>

				<CollapseBlock title="Размер" defaultOpen={false}>
					<Controller
						name="size"
						control={control}
						render={({ field }) => (
							<RadioGroup {...field} value={field.value || ''}>
								{Object.values(CONTAINER_SIZE).map((size) => (
									<FormControlLabel key={size.id} value={size.id} control={<Radio />} label={size.value} />
								))}
							</RadioGroup>
						)}
					/>
				</CollapseBlock>

				<div style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '12px' }}>
					{/* @ts-ignore */}
					<Switcher
						onChecked={(checked) => setValue('isFilled', checked, { shouldTouch: true })}
						externalChecked={isFilled}
						right="Заполнен"
					/>
					{/* @ts-ignore */}
					<Switcher
						onChecked={(checked) => setValue('isDangerous', checked, { shouldTouch: true })}
						externalChecked={isDangerous}
						right="Опасный груз"
					/>
					{/* @ts-ignore */}
					<Switcher
						onChecked={(checked) => setValue('isDamaged', checked, { shouldTouch: true })}
						externalChecked={isDamaged}
						right="Поврежден"
					/>
				</div>
			</div>
		</FormWrapper>
	);
};
