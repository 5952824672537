import { Radio, RadioGroup, FormControlLabel, Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CONTAINER_SIZE, CONTAINER_TYPE } from '../../../shared/containers/constants';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import InputText from '../../Inputs/InputText/InputText';
import { Switcher } from '../../../components';
import { DrawerModal } from '../../DrawerModal/DrawerModal';
import { FormWrapper } from '../FormWrapper';
import { LineInputAutocompleteController } from '../Fields/LineInputAutocompleteController';
import { TerminalInputAutocompleteController } from '../Fields/TerminalInputAutocompleteController';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { ClientInputAutocompleteController } from '../Fields/ClientInputAutocompleteController';
import { useInitFormState } from './hooks/useInitFormState';
import { isClient } from '../../../shared/auth/isClient';
import { ImportContainers } from '../../../shared/ui/ImportContainers/ImportContainers';
import { useGetFormSchema } from './hooks/useGetFormSchema';

export const ContainerForm = ({
	isOpen,
	isImport = false,
	handleClose,
	refetchData,
	entityIds = [],
	notEditableValues = {},
	createdCallback = (containerId) => { }
}) => {
	const [isImportOpen, setIsImportOpen] = useState(false);

	const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
	const isBulkUpdate = useMemo(() => entityIds.length > 1, [entityIds]);
	const isCreate = useMemo(() => entityIds.length === 0 && !isImport, [entityIds, isImport]);

	const { resolver } = useGetFormSchema({ isCreate, isImport })

	const methods = useForm({
		defaultValues: {
			line: undefined,
			containerNumber: '',
			size: undefined,
			type: undefined,
			client: undefined,
			bookingNumber: '',
			isFilled: false,
			isDangerous: false,
			isDamaged: false,
			terminal: undefined,
		},
		resolver: resolver,
	});
	const { handleSubmit, control, watch, setValue, formState: { errors } } = methods;

	useEffect(() => {
		if (notEditableValues['client'])
			setValue('client', +notEditableValues['client'], { shouldTouch: true })
		if (notEditableValues['terminal'])
			setValue('terminal', +notEditableValues['terminal'], { shouldTouch: true })
	}, [notEditableValues, setValue]);

	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, handleClose, refetchData, createdCallback });
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, handleClose, refetchData });
	useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

	const isFilled = watch('isFilled');
	const isDangerous = watch('isDangerous');
	const isDamaged = watch('isDamaged');
	const terminalValue = watch('terminal');
	const clientValue = watch('client');

	const importDisabled = useMemo(() => !terminalValue && !clientValue, [terminalValue, clientValue]);

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) handleCreate(value);
			else handleUpdate(value);
		},
		[handleCreate, handleUpdate, isCreate],
	);

	const handleOpenImportPopup = useCallback(() => {
		if (importDisabled)
			handleSubmit(() => {})()
		else
			setIsImportOpen(true)
	}, [importDisabled, handleSubmit]);

	const renderActionBtn = useMemo(() => {
		if (isImport)
			return (
				<Button onClick={handleOpenImportPopup} variant="contained" color="primary">
					Импортировать
				</Button>
			)
		return (
			<Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
				Сохранить
			</Button>
		)
	}, [isImport, isCreateLoading, isUpdateLoading, handleSubmit, onSubmit, handleOpenImportPopup]);

	return (
		<>
			<DrawerModal
				isOpen={isOpen}
				handleClose={handleClose}
				actions={renderActionBtn}
			>
				<Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
				<FormWrapper onSubmit={onSubmit} {...methods}>
					{!notEditableValues['terminal'] && <TerminalInputAutocompleteController />}
					{!isClient() && !notEditableValues['client'] && <ClientInputAutocompleteController />}
					{!isImport && <>
						{!isBulkUpdate && (
							<CollapseBlock defaultOpen={!!errors.containerNumber} title={<span>№ Контейнера</span>}>
								<Controller
									name="containerNumber"
									control={control}
									render={({ field }) => (
										<>
											<InputText {...field} placeholder="Введите номер" />
											{errors?.containerNumber && (
												<Typography color="error" variant="caption">
													{errors?.containerNumber?.message}
												</Typography>
											)}
										</>
									)}
								/>
							</CollapseBlock>
						)}
						<LineInputAutocompleteController />
						<CollapseBlock title="Размер">
							<Controller
								name="size"
								control={control}
								render={({ field }) => (
									<RadioGroup {...field}>
										{Object.values(CONTAINER_SIZE).map((size) => (
											<FormControlLabel key={size.id} value={size.id} control={<Radio />} label={size.value} />
										))}
									</RadioGroup>
								)}
							/>
						</CollapseBlock>
						<CollapseBlock title="Тип">
							<Controller
								name="type"
								control={control}
								render={({ field }) => (
									<RadioGroup {...field}>
										{Object.values(CONTAINER_TYPE).map((type) => (
											<FormControlLabel key={type.id} value={type.id} control={<Radio />} label={type.value} />
										))}
									</RadioGroup>
								)}
							/>
						</CollapseBlock>
						<CollapseBlock title="Букинг">
							<Controller
								name="bookingNumber"
								control={control}
								render={({ field }) => <InputText {...field} placeholder="Введите номер" />}
							/>
						</CollapseBlock>
						<CollapseBlock title="Заполнен">
							<Switcher
								onChecked={(checked) => setValue('isFilled', checked, { shouldTouch: true })} // Setting value in form state
								externalChecked={isFilled}
								right="Да"
							/>
						</CollapseBlock>
						<CollapseBlock title="Опасный груз">
							<Switcher
								onChecked={(checked) => setValue('isDangerous', checked, { shouldTouch: true })} // Setting value in form state
								externalChecked={isDangerous}
								right="Да"
							/>
						</CollapseBlock>
					</>}
				</FormWrapper>
			</DrawerModal>
			<ImportContainers
				isOpen={isImportOpen}
				toggleIsOpen={(flag) => {
					setIsImportOpen(flag);
					handleClose();
				}}
				defaultCreateFields={{
					terminalId: terminalValue,
					orgId: clientValue
				}}
				importedCallback={(containerIds) => refetchData()}
			/>
		</>
	);
};
