import { Controller, useFormContext } from 'react-hook-form';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import { Typography } from '@mui/material';
import { InputAutocomplete } from '../../Inputs/InputAutocomplete/InputAutocomplete';

export const UnitRepairInputAutocompleteController = () => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<CollapseBlock defaultOpen={!!errors?.unit} title={<span>Единица</span>}>
			<Controller
				name="unit"
				control={control}
				render={({ field }) => (
					<>
						<InputAutocomplete
							{...field}
							placeholder="Выберите"
							options={[
								{ id: 0, label: 'Длина' },
								{ id: 1, label: 'Штуки' },
								{ id: 2, label: 'Площадь' },
								{ id: 2, label: 'ТЕУ/ДФЭ' },
							]}
						/>
						{errors?.unit && (
							<Typography color="error" variant="caption">
								{errors?.unit?.message}
							</Typography>
						)}
					</>
				)}
			/>
		</CollapseBlock>
	);
};
