import React, { useMemo } from 'react';
import UniversalBlock from '../components/UniversalBlock';
import styles from './SideStyle.module.scss';
import { isBlockChoosen } from './helpers/isBlockChoosen';
import { sideTypeToName } from './helpers/sideTypeToName';
import { panelNameToPanelAvailableCodes } from './helpers/panelNameToPanelAvailableCodes';
import UniversalDetailBlock from '../components/UniversalDetailBlock';
import { isDetailDamaged } from './helpers/isDetailDamaged';
import { getSelectedComponent } from '../../../../../../../../../../shared/inspection/utils';

function RightSide({ feet, selectedSide, setSmthSelected, selectedAreas, selectedDamageDetails=[], damagesList = [] }) {
	const { placement_code_description, components } = selectedSide;

	const getActiveComponent = useMemo(() => {
		return getSelectedComponent({ components, option: selectedAreas[0]?.divider, part: selectedAreas[0]?.part })
	}, [selectedAreas, components]);

	return (
		<div className={styles.mainBlock_new}>
			<div className={styles.mapRowNumber_new}>
				<p className={styles.topNumber_new}></p>
				{placement_code_description[0]?.parts[`${feet}-feet`].slice().map((part) => (
					<p key={part} className={styles.topNumber_new}>
						{part}
					</p>
				))}
			</div>
			{placement_code_description.map(({ option, parts }) => (
				<div key={option} className={styles.mapRow_new}>
					<p className={styles.sideLabel_new}>{option}</p>

					{parts[`${feet}-feet`].slice().map((part, index) => (
						<UniversalBlock
							key={`${part}${option}`}
							type={getType(option, part, feet)}
							sideIndex={'R'}
							sideName={sideTypeToName('R')}
							divider={option}
							part={part.toString()}
							partCode={panelNameToPanelAvailableCodes(components, 'R', option, part)}
							onClick={setSmthSelected}
							selectedAreas={selectedAreas}
							choosen={isBlockChoosen(damagesList, 'R', option, part.toString())}
							selectedComponent={getActiveComponent}
						/>
					))}
				</div>
			))}
			<div className={styles[`forkPocketsContainer_${feet}`]}>
				<UniversalDetailBlock
					type={'Left_forkpocket_RL'}
					sideIndex={'R'}
					sideName={'Правая сторона'}
					divider={'-'}
					part={'Left_forkpocket_RL'}
					onClick={setSmthSelected}
					selectedAreas={selectedAreas}
					choosen={isDetailDamaged(damagesList, selectedDamageDetails, 'Left_forkpocket_RL')}
				/>
				<UniversalDetailBlock
					type={'Right_forkpocket_RR'}
					sideIndex={'R'}
					sideName={'Правая сторона'}
					divider={'-'}
					part={'Right_forkpocket_RR'}
					onClick={setSmthSelected}
					selectedAreas={selectedAreas}
					choosen={isDetailDamaged(damagesList, selectedDamageDetails, 'Right_forkpocket_RR')}
				/>
			</div>
		</div>
	);
}

function getType(option, part, feet) {
	if (option === 'H') {
		return `beam-horizontal-H`;
	} else if (option === 'G') {
		return `beam-horizontal-B`;
	} else {
		return 'mainPanel';
	}
}
export default RightSide;
