import { Typography, Box, Grid, Divider, Chip } from '@mui/material';
import { Card } from '../../../../shared/ui/Card/Card';

type InspectionActCardProps = {
	id?: number;
	status?: string;
	colorStatus?: any;
	client?: string;
	type?: string;
	dateCreated?: string;
	containerNumber?: string;
};

export const InspectionActCard = ({ id, status, colorStatus, client, containerNumber, type, dateCreated }: InspectionActCardProps) => {
	return (
		<Card>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ padding: '24px 24px 12px', display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item>
							<Typography variant="subtitle1">Акт №{id}</Typography>
						</Grid>

						<Grid item>
							<Chip label={status} color={colorStatus} />
						</Grid>
					</Grid>

					{dateCreated !== undefined && <Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Дата создания:</Typography>
							<Typography variant="body2">{dateCreated}</Typography>
						</Grid>
					</Grid>}
					{type !== undefined && <Grid container sx={{ gap: '12px' }}>
						<Grid item>
							<Chip label={`Тип: ${type}`} />
						</Grid>
					</Grid>}
				</Box>
				<Divider />
				<Box
					sx={{
						padding: '16px 24px',
					}}
				>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Клиент</Typography>
							<Typography variant="overline">{client}</Typography>
						</Grid>
						<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Контейнер</Typography>
							<Typography variant="overline">{containerNumber}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Card>
	);
};
