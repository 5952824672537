import { useEffect } from 'react';
import { useGetEntryQuery } from '../../../../store/newApi/entries/entries';
import { useParams } from 'react-router-dom';
import { handleFindWorker } from '../../../../shared/empls/utils';

export const useInitFormState = ({ data, isCreate, ...methods }) => {
	const { setValue } = methods; 

	useEffect(() => {
		if (data) {
			setValue('container_number', data?.container?.container_number);
			setValue('vehicle_driver', data?.vehicle_driver);
			setValue('vehicle', data?.vehicle);
			setValue('vehicle_trailer_number', data?.vehicle_trailer_number);
			setValue('seal_number', data?.seal_number);
			if (data?.assignees) { 
				setValue('assignee', handleFindWorker({ assignees: data?.assignees, type: 0, item: 'worker' }));
			}
		}
	}, [data, setValue]);
};
