import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import { ConfirmationModalProvider } from './shared/providers/PopupProvider/ModalProvider';
import { SideMenuProvider } from './shared/providers/SideMenuProvider/SideMenuProvider';
import { AlertProvider } from 'shared/providers/AlertProvider/AlertProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AlertProvider>
      <SideMenuProvider>
        <ConfirmationModalProvider>
          <App />
        </ConfirmationModalProvider>
      </SideMenuProvider>
    </AlertProvider>
  </Provider>
); 