import { Typography, Box, Grid, Divider, Chip } from '@mui/material';
import { Card } from '../../../../shared/ui/Card/Card';

type OrderCardProps = {
	orderId: number;
	status?: string;
	colorStatus?: any;
	client?: string;
	expedition?: string;
	type?: string;
	arrived?: number;
	canceled?: number;
	expected?: number;
	total?: number;
	dateCreated?: string;
	dateComplete?: string;
};

export const OrderCard = ({
	orderId,
	status,
	colorStatus,
	client,
	expedition,
	type,
	arrived,
	canceled,
	expected,
	total,
	dateCreated,
	dateComplete,
}: OrderCardProps) => {
	return (
		<Card>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ padding: '24px 24px 12px', display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item>
							<Typography variant="subtitle1">Заявка №{orderId}</Typography>
						</Grid>

						<Grid item>
							<Chip label={status} color={colorStatus} />
						</Grid>
					</Grid>

					<Typography variant="subtitle2">{client}</Typography>

					{(expedition || type) && <Grid container sx={{ gap: '12px' }}>
						{expedition && <Grid item>
							<Chip label={expedition} color="primary" />
						</Grid>}

						{type && <Grid item>
							<Chip label={type} color="primary" />
						</Grid>}
					</Grid>}

					{(total !== undefined || arrived !== undefined || expected !== undefined || canceled !== undefined) &&
						<Grid container sx={{ justifyContent: 'space-between' }}>
							{total !== undefined && <Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
								<Typography variant="body1">Всего:</Typography>
								<Typography variant="body2">{total}</Typography>
							</Grid>}
							{arrived !== undefined && <Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
								<Typography variant="body1">Прибыло:</Typography>
								<Typography variant="body2">{arrived}</Typography>
							</Grid>}
							{expected !== undefined && <Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
								<Typography variant="body1">Ожидается:</Typography>
								<Typography variant="body2">{expected}</Typography>
							</Grid>}
							{canceled !== undefined && <Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
								<Typography variant="body1">Отменено:</Typography>
								<Typography variant="body2">{canceled}</Typography>
							</Grid>}
						</Grid>}
				</Box>
				{(dateCreated || dateComplete) && (
					<>
						<Divider />
						<Box
							sx={{
								padding: '16px 24px',
							}}
						>
							<Grid container sx={{ justifyContent: 'space-between' }}>
								<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
									<Typography variant="caption">Дата создания</Typography>
									<Typography variant="overline">{dateCreated}</Typography>
								</Grid>
								<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
									<Typography variant="caption">Примерная дата завершения</Typography>
									<Typography variant="overline">{dateComplete}</Typography>
								</Grid>
							</Grid>
						</Box>
					</>
				)}
			</Box>
		</Card>
	);
};
