export const inputDropdown = {
	textField: {
		backgroundColor: (theme) => theme.palette.input.background,
		borderRadius: '8px',
		border: '1px solid',
		borderColor: (theme) => theme.palette.input.borderColor,
		padding: '5px 16px',
		justifyContent: 'center',
		color: (theme) => theme.palette.input.color,
		transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease',
		
		input: {
			padding: '0px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.04px',
			color: (theme) => theme.palette.input.color,
		},

		'> div': {
			padding: '0px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover': { 
			backgroundColor: (theme) => theme.palette.input.backgroundActive,
			borderColor: (theme) => theme.palette.input.borderColorActive,
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: (theme) => theme.palette.input.borderColorActive,
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: (theme) => theme.palette.input.borderColorActive,
		},
	},
	selectedTextField: {
		borderColor: (theme) => theme.palette.input.borderColorActive,
		backgroundColor: (theme) => theme.palette.input.backgroundActive,
		input: {
			padding: '0px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.04px',
			color: (theme) => theme.palette.input.colorValue,
		},
	},
	arrowIcon: {
		width: 16,
		height: 16,
		color: '#939DAC',
		transform: (open) => (open ? 'rotate(90deg)' : 'rotate(0deg)'),
		transition: 'transform 0.3s ease',
		cursor: 'pointer',
	},
};
