import { useCallback, useEffect } from 'react';
import { useCreateClientMutation } from '../../../../../../../../store/newApi/organizations/organizations';

export const useHandleCreate = ({ ...methods }) => {
	const { reset: resetForm } = methods;
	const [createClient, { data, isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateClientMutation }] =
		useCreateClientMutation();

	useEffect(() => {
		if (isCreateSuccess) {
			resetCreateClientMutation();
			resetForm(); 
		}

		return () => {
			resetForm();
		};
	}, [isCreateSuccess, resetCreateClientMutation, resetForm]);

	const handleCreate = useCallback(
		({ user_type, value }) => {
			const payload = {
				user_type: user_type,
				is_confirmed: true,
				full_name: value.full_name,
				password: value.password,
				email: value.email,
				organization: {
					organization_address: value.organization_address,
					organization_name: value.organization_name,
					inn: value.inn,
					terminals: value.terminals,
					can_confirm_repair: value.isCan_confirm_repair,
					can_see_storage_price: value.isCan_see_storage_price,
					legal_entities: value.legalEntity,
				}, 
			};

			if (value.assignee) {
				payload['set_assignee'] = {
					worker: value.assignee,
					type: 0,
				}
			} 

			createClient({ 
				...payload,
			}); 
		},
		[createClient],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
		data,
	};
};
