import React from 'react';
import styles from './ContainerMap.module.scss';
import { FrontEnd, RoofSide } from './Sides';
import FrontEndWithDoor from './Sides/FrontEndWithDoor';
import LeftSide from './Sides/LeftSide';
import RightSide from './Sides/RightSide';
import BottomSide from './Sides/BottomSide_U';
import FloorSide from './Sides/FloorSide_B';

function ContainerMap({
	selectedSide,
	entryOneReport,
	setSmthSelected,
	selectedAreas,
	selectedDamageDetails = [],
	damagesList = []
}) {
	return (
		<>
			<p className={styles.mainTitle}>{selectedSide?.title}</p>
			<div className={styles.mainMapBlock}>
				<div className={styles.overflowBlock}>
					{selectedSide?.code === 'L' ? (
						<LeftSide
							setSmthSelected={setSmthSelected}
							selectedAreas={selectedAreas}
							selectedSide={selectedSide}
							feet={entryOneReport.container.size_display === '20' ? 20 : 40}
							damagesList={damagesList}
							selectedDamageDetails={selectedDamageDetails}
						/>
					) : selectedSide?.code === 'R' ? (
						<RightSide
							setSmthSelected={setSmthSelected}
							selectedAreas={selectedAreas}
							selectedSide={selectedSide}
							feet={entryOneReport.container.size_display === '20' ? 20 : 40}
							damagesList={damagesList}
							selectedDamageDetails={selectedDamageDetails}
						/>
					) : selectedSide?.code === 'T' ? (
						<RoofSide
							setSmthSelected={setSmthSelected}
							selectedAreas={selectedAreas}
							selectedSide={selectedSide}
							feet={entryOneReport.container.size_display === '20' ? 20 : 40}
							damagesList={damagesList}
							selectedDamageDetails={selectedDamageDetails}
						/>
					) : selectedSide?.code === 'B' ? (
						<FloorSide
							setSmthSelected={setSmthSelected}
							selectedAreas={selectedAreas}
							selectedSide={selectedSide}
							feet={entryOneReport.container.size_display === '20' ? 20 : 40}
							damagesList={damagesList}
							selectedDamageDetails={selectedDamageDetails}
						/>
					) : selectedSide?.code === 'U' ? (
						<BottomSide
							setSmthSelected={setSmthSelected}
							selectedAreas={selectedAreas}
							selectedSide={selectedSide}
							feet={entryOneReport.container.size_display === '20' ? 20 : 40}
							damagesList={damagesList}
							selectedDamageDetails={selectedDamageDetails}
						/>
					) : selectedSide?.code === 'F' ? (
						<FrontEnd
							setSmthSelected={setSmthSelected}
							selectedAreas={selectedAreas}
							selectedSide={selectedSide}
							feet={entryOneReport.container.size_display === '20' ? 20 : 40}
							damagesList={damagesList}
							selectedDamageDetails={selectedDamageDetails}
						/>
					) : selectedSide?.code === 'D' ? (
						<FrontEndWithDoor
							setSmthSelected={setSmthSelected}
							selectedAreas={selectedAreas}
							selectedSide={selectedSide}
							feet={entryOneReport.container.size_display === '20' ? 20 : 40}
							damagesList={damagesList}
							selectedDamageDetails={selectedDamageDetails}
						/>
					) : (
						<></>
					)}
					<div className={styles.bottomP}>
						<p className={styles.mainTitle}>
							{selectedSide?.title === 'Левая сторона L'
								? 'Передняя сторона'
								: selectedSide?.title === 'Правая сторона R' ||
									  selectedSide?.title === 'Крыша T' ||
									  selectedSide?.title === 'Пол B' ||
									  selectedSide?.title === 'Низ U'
									? 'Двери'
									: ''}
						</p>
						<p className={styles.mainTitle}>
							{selectedSide?.title === 'Левая сторона L'
								? 'Двери'
								: selectedSide?.title === 'Правая сторона R' ||
									  selectedSide?.title === 'Крыша T' ||
									  selectedSide?.title === 'Пол B' ||
									  selectedSide?.title === 'Низ U'
									? 'Передняя сторона'
									: ''}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default ContainerMap;
