import { OrdersList } from 'entities/order/ui/OrdersList/OrdersList';
import styles from '../../AccountEmpl.module.scss';
import Header from 'newComponents/Header/Header';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PageTabs } from 'shared/ui';
import { InspectionList } from 'entities/inspection/ui/InspectionList/InspectionList';
import { RepairList } from 'entities/repair/ui/RepairList/RepairList';
import { CreateOrderButton } from 'entities/order/ui/CreateOrderButton/CreateOrderButton';
import { InspectionCreateButton } from 'entities/inspection/ui/InspectionCreateButton/InspectionCreateButton';
import { CreateRepairButton } from 'entities/repair/ui/CreateRepairButton/CreateRepairButton';

function AccountEmplMain() {
	const navigate = useNavigate();
	const location = useLocation();

	const [params] = useSearchParams();
    const step = params.get('step');
	const [currentStep, setCurrentStep] = useState(0);
	
	useEffect(() => {
		setCurrentStep(+step);
	}, [step]);

	const handleChangeStep = (step) => {
        navigate(`${location.pathname}?step=${step}`)
    }

	const renderStep = () => {
		switch(currentStep) {
			case 0:
				return <OrdersList />
			case 1:
				return <InspectionList />;
			case 2:
				return <RepairList />;
			default:
				return null;
		}
	}

	const getRightActions = useMemo(() => {
		if (currentStep === 0) {
			return <CreateOrderButton />
		}
		if (currentStep === 1) {
			return <InspectionCreateButton />
		}
		if (currentStep === 2) {
			return <CreateRepairButton />
		}
	}, [currentStep]);

	return (
		<div className={styles.account__wrap}>
			{/* @ts-ignore */}
			<Header text={'Главная'} />
			<PageTabs
                tabs={[
                    { title: 'Заяки', step: 0 },
                    { title: 'Осмотр', step: 1 },
                    { title: 'Ремонтные работы', step: 2 },
                ]}
				rightActions={getRightActions}
                onTabClick={handleChangeStep}
                currentStep={currentStep}
			/>
			<div className={styles.account__collection}>
				{renderStep()}
			</div>
		</div>
	);
}

export default AccountEmplMain;
