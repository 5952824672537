import { isObject } from "shared/utils/object/isObject";

const EMPL_FORM_FIELDS_ERROR = {
    username: 'Логин'
}

const FORMS_FIELDS = {
    ...EMPL_FORM_FIELDS_ERROR,
}

export const tranformErrorsToRender = (error: Record<string, string[]> = {}, customMessage: string) => {
    if (!isObject(error)) return customMessage;
    const firstError = Object.entries(error)[0];
    if (Array.isArray(firstError) && firstError.length > 0) {
        const renderedField = FORMS_FIELDS[firstError[0]];
        const renderedMessage = firstError[1];
        return `${renderedMessage} в поле ${renderedField}`;
    }
    return customMessage;
}