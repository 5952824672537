import { useCallback, useEffect } from 'react';
import { useCreateStaffMutation } from '../../../../../../../store/newApi/users/users';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';

export const useHandleCreate = ({ successCallback = () => {}, ...methods }) => {
	const { showNotificaton } = useNotify();
	const [createStaff, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateStaffMutation }] =
		useCreateStaffMutation();

	const handleCreate = useCallback(
		({ value, staff_terminal }) => {
			createStaff({
				full_name: value.full_name,
				password: value.password,
				username: value.username,
				staff_terminal: staff_terminal,
				email: value.email,
				phone: value.phone, 
			})
				.then((data) => {
					if (data.error) {
						showNotificaton({ 
							type: 'error',
							message: tranformErrorsToRender(data.error?.data, 'Не удалось создать сотрудника')
						})
					} else {
						resetCreateStaffMutation(); 
						successCallback();
						showNotificaton({ 
							type: 'success',
							message: 'Сотрудник создан'
						})
					}
				})
		},
		[createStaff],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
	};
};
