import { useCallback, useEffect } from 'react';
import { filterTouchedFields } from '../../utils/filterTouchedFields';
import { useUpdateEntryMutation } from '../../../../store/newApi/entries/entries';
import { useParams } from 'react-router-dom';

export const useHandleUpdate = ({ refetchData, ...methods }) => {
	let { terminalId, orderId, entryId } = useParams();
	const {
		formState: { touchedFields },
		reset: resetForm,
	} = methods;
	const [update, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdate }] = useUpdateEntryMutation();

	useEffect(() => {
		if (isUpdateSuccess) {
			resetUpdate(); 
			refetchData(); 
		} 
	}, [isUpdateSuccess, resetUpdate, refetchData]);

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = {
				vehicle: value?.vehicle,
				container_number: touchedFieldValues?.container_number,
				vehicle_driver: touchedFieldValues?.vehicle_driver,
				vehicle_trailer_number: touchedFieldValues?.vehicle_trailer_number,
				seal_number: touchedFieldValues?.seal_number,
			};

			if (touchedFieldValues.assignee) {
				payload['set_assignee'] = {
					worker: touchedFieldValues.assignee,
					type: 0,
				};
			}

			update({
				terminalId,
				orderId,
				pass_pk: entryId,
				...payload,
			});
		},
		[update, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
