import { Typography } from '@mui/material';
import Header from '../../../../../newComponents/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import InputText from '../../../../../newComponents/Inputs/InputText/InputText';
import { useCallback, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useNavigate, useParams } from 'react-router-dom';
import { useInitFormState } from './hooks/useInitFormState';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import styles from './AccountEmplLegalEntity.module.css';
import { PageFooterActions } from 'shared/ui/PageFooterActions/PageFooterActions';

const createSchema = z.object({
	full_name_organization: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	eng_full_name_organization: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	inn: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	kpp: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	ogrn: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	legal_address: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	actual_address: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	contact_number: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	direct_name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
});

const updateSchema = z.object({
	full_name_organization: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	eng_full_name_organization: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	inn: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	kpp: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	ogrn: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	legal_address: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	actual_address: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	contact_number: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	direct_name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
});

export const AccountEmplLegalEntity = () => {
	const navigate = useNavigate();
	const { legalEntityId } = useParams();
	const resolver = useMemo(() => (legalEntityId ? zodResolver(updateSchema) : zodResolver(createSchema)), [legalEntityId]);
	const methods = useForm({
		defaultValues: {
			full_name_organization: '',
			eng_full_name_organization: '',
			inn: '',
			kpp: '',
			ogrn: '',
			legal_address: '',
			actual_address: '',
			contact_number: '',
			direct_name: '',
		},
		resolver: resolver,
	});

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = methods;

	const { handleCreate } = useHandleCreate({ ...methods });
	const { handleUpdate } = useHandleUpdate({ ...methods, legalEntityId });
	useInitFormState({ ...methods, legalEntityId });

	const onSubmit = useCallback(
		(value) => {
			if (legalEntityId) handleUpdate(value);
			else handleCreate(value);
		},
		[handleCreate, handleUpdate, legalEntityId],
	);

	return (
		<div style={{ width: '100%' }}>
			{/* @ts-ignore */}
			<Header text={legalEntityId ? watch('full_name_organization') : 'Создать юридическое лицо'} />

			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<div className={styles.form}>
						<Controller
							name="full_name_organization"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="Полное название организации" placeholder="Введите название" />
									{errors?.full_name_organization && (
										<Typography color="error" variant="caption">
											{errors?.full_name_organization?.message}
										</Typography>
									)}
								</div>
							)}
						/>

						<Controller
							name="eng_full_name_organization"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="Название организации на английском" placeholder="Введите название" />
									{errors?.eng_full_name_organization && (
										<Typography color="error" variant="caption">
											{errors?.eng_full_name_organization?.message}
										</Typography>
									)}
								</div>
							)}
						/>

						<Controller
							name="inn"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="ИНН" placeholder="Введите инн" />
									{errors?.inn && (
										<Typography color="error" variant="caption">
											{errors?.inn?.message}
										</Typography>
									)}
								</div>
							)}
						/>

						<Controller
							name="kpp"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="КПП" placeholder="Введите КПП" />
									{errors?.kpp && (
										<Typography color="error" variant="caption">
											{errors?.kpp?.message}
										</Typography>
									)}
								</div>
							)}
						/>

						<Controller
							name="ogrn"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="ОГРН" placeholder="Введите ОГРН" />
									{errors?.ogrn && (
										<Typography color="error" variant="caption">
											{errors?.ogrn?.message}
										</Typography>
									)}
								</div>
							)}
						/>

						<Controller
							name="legal_address"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="Юридический адрес" placeholder="Введите адрес" />
									{errors?.legal_address && (
										<Typography color="error" variant="caption">
											{errors?.legal_address?.message}
										</Typography>
									)}
								</div>
							)}
						/>

						<Controller
							name="actual_address"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="Фактический адрес" placeholder="Введите адрес" />
									{errors?.actual_address && (
										<Typography color="error" variant="caption">
											{errors?.actual_address?.message}
										</Typography>
									)}
								</div>
							)}
						/>

						<Controller
							name="contact_number"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="Номер телефона" placeholder="Введите номер" />
									{errors?.contact_number && (
										<Typography color="error" variant="caption">
											{errors?.contact_number?.message}
										</Typography>
									)}
								</div>
							)}
						/>

						<Controller
							name="direct_name"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="ФИО Руководителя" placeholder="Введите ФИО" />
									{errors?.direct_name && (
										<Typography color="error" variant="caption">
											{errors?.direct_name?.message}
										</Typography>
									)}
								</div>
							)}
						/>
					</div>
				</div>
				<PageFooterActions
					leftBtns={[
						{
							text: 'Назад',
							variant: 'outlined',
							color: 'primary',
							size: 'medium',
							onClick: () => navigate('/accountEmpl/legal'),
						},
					]}
					rightBtns={[
						{
							text: legalEntityId ? 'Сохранить' : 'Добавить',
							variant: 'contained',
							color: 'primary',
							size: 'medium',
							onClick: handleSubmit(onSubmit),
						},
					]}
				/>
			</div>
		</div>
	);
};
