import { useEffect, useState } from 'react';
import { InputAutocomplete } from '../../../../../../newComponents/Inputs/InputAutocomplete/InputAutocomplete';
import styles from '../AccountEmplContainer.module.css';
import { ContainerPhotoViewer } from './ContainerPhotoViewer';
import { useLazyGetEntryReportByIdQuery } from '../../../../../../store/newApi/entryreports/entryreports';
import { useLazyGetServiceOrdersByIdQuery } from '../../../../../../store/newApi/service/service';

type Option = {
	label: string;
	id: any;
	type: string;
};

type ContainerActsProps = {
	options: Option[];
};

export const ContainerActs = ({ options }: ContainerActsProps) => {
	const [chooseAct, setChooseAct] = useState('');

	const [fetchEntryReport, { entryReportDamages = [], entryReportImgs = [], data: entryReportData }] = useLazyGetEntryReportByIdQuery({
		selectFromResult: ({ data }) => {
			const entryReportDamagesMap = data?.damages
				?.map((el) => el?.pictures)
				?.map((picture) => picture[0])
				?.flat()

			return {
				entryReportDamages: entryReportDamagesMap || [],
				entryReportImgs: data?.report_photos || [],
				data,
			};
		},
	});

	const [fetchServiceOrder, { serviceOrderImgs = [], serviceOrderFix = [], data: serviceOrderData }] = useLazyGetServiceOrdersByIdQuery({
		selectFromResult: ({ data }) => {
			const serviceOrderImgsMap = data?.repair_group
				?.map((el) => el?.damage)
				?.map((el) => el?.pictures)
				?.map((picture) => picture[0])
				?.flat()

			const serviceOrderFixMap =
				data?.repair_group
					?.map((el) => el?.fix_pictures)
					?.flat()
					?.filter((el) => el !== undefined && el !== null);

			return {
				serviceOrderImgs: serviceOrderImgsMap || [],
				serviceOrderFix: serviceOrderFixMap || [],
				data,
			};
		},
	});

	const handleChooseAct = (id) => {
		if (id) {
			setChooseAct(id);
		}
	};

	useEffect(() => {
		if (chooseAct) {
			const selectedAct = options.find((option) => option.id === chooseAct);

			if (selectedAct?.type === 'repairs') {
				fetchServiceOrder({ id: selectedAct.id });
			}
			if (selectedAct?.type === 'entry_reports') {
				fetchEntryReport({ id: selectedAct.id });
			}
		}
	}, [chooseAct]);

	return (
		<>
			<InputAutocomplete
				value={chooseAct}
				onChange={(id) => handleChooseAct(id)}
				placeholder="Выберите"
				options={options}
				labelText={'Акты контейнера'}
			/>

			{entryReportData && chooseAct === entryReportData?.id && (
				<div className={styles.viewers}>
					<ContainerPhotoViewer title={'Фотографии с осмотра'} images={entryReportImgs} />
					<ContainerPhotoViewer title={'Фотографии повреждений'} images={entryReportDamages} />
				</div>
			)}

			{serviceOrderData && chooseAct === serviceOrderData?.id && (
				<div className={styles.viewers}>
					<ContainerPhotoViewer title={'Фотографии повреждений'} images={serviceOrderImgs} />
					<ContainerPhotoViewer title={'Фотографии ремонта'} images={serviceOrderFix} />
				</div>
			)}
		</>
	);
};
