import { InputAutocompleteAsync } from '../../Inputs/InputAutocomplete/InputAutocompleteAsync';
import { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useLazyGetEmplsListQuery } from '../../../store/newApi/users/users';
import { formatISO } from '../../../helpers/formatDate';
import { AssigneesInputAutocompleteProps } from '../../../shared/empls/types';
import { isEmpl } from '../../../shared/auth/isEmpl';
import { isClient } from '../../../shared/auth/isClient';
import InputText from '../../Inputs/InputText/InputText';

// @ts-ignore
export const historyBlock = {
	width: '100%',
	display: 'flex',
	flexDirection: 'column-reverse',
	gap: '4px',
	overflowY: 'auto',
	maxHeight: '100px',

	'&::-webkit-scrollbar': {
		width: '2px',
	},

	'&::-webkit-scrollbar-track': {
		backgroundColor: '#e4dfff',
	},

	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#8f86de',
	},
};

export const AssigneesInputAutocomplete = ({
	terminal,
	group,
	assigneesHistory,
	value,
	valueName,
	onChange,
	...rest
}: AssigneesInputAutocompleteProps) => {
	// @ts-ignore
	const [trigger, { staffOptions = [], loading: loadingStaff }] = useLazyGetEmplsListQuery({
		selectFromResult: ({ data }) => ({
			staffOptions: data?.results?.map((item) => ({ label: item.full_name, id: item.id })),
		}),
	}); 

	const handleSearch = useCallback(
		(value) => {
			trigger({
				search: value,
				terminal: terminal,
				user_group: group,
			});
		},
		[trigger, group, terminal],
	);

	return (
		<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
			{isEmpl() && (
				<InputAutocompleteAsync
					{...rest}
					value={value}
					fetchFn={handleSearch}
					placeholder="Выберите или введите вручную"
					options={staffOptions}
					loading={loadingStaff}
					onChange={onChange}
				/>
			)}
			{/* @ts-ignore */}
			{isClient() && <InputText labelText={'Ответственный менеджер'} placeholder={'Отсутсвует'} value={valueName} disabled={true} />}
			{assigneesHistory && (
				<Box sx={historyBlock}>
					{assigneesHistory.map((el, ind) => (
						<Box key={ind} sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '2px' }}>
							<Typography>{el.assignee_name}</Typography>
							<Typography>-</Typography>
							<Typography>{el.type_display}</Typography>
							<Typography>-</Typography>
							<Typography>{formatISO(el.created_at, 'dd.MM.yyyy')}</Typography>
							<Typography>-</Typography>
							<Typography>{formatISO(el.disabled_at, 'dd.MM.yyyy')}</Typography>
						</Box>
					))}
				</Box>
			)}
		</Box>
	);
};
