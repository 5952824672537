import { useEffect, useRef, useState } from 'react';
import { Menu, MenuItem, TextField, InputAdornment } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { inputDropdown } from './InputDropdown.styles.js';

// TODO: Заменить все использования на InputAutocomplete
function InputDropdown({ placeholder, dropdownItems, onSelect, state }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedValue, setSelectedValue] = useState('');
	const [menuWidth, setMenuWidth] = useState('auto');
	const inputRef = useRef(null);
	const open = Boolean(anchorEl);

	useEffect(() => {
		if (state) {
			const selectedItem = dropdownItems.find((item) => item.value === state);
			if (selectedItem) {
				setSelectedValue(selectedItem.label);
			} else {
				setSelectedValue('');
			}
		} else {
			setSelectedValue('');
		}
	}, [state]);

	useEffect(() => {
		if (inputRef.current) {
			setMenuWidth(inputRef.current.offsetWidth);
		}
	}, [anchorEl]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (label) => {
		setSelectedValue(label);
		handleClose();
	};

	const handleSelectFilter = (value) => {
		if (onSelect) {
			onSelect(value);
		}
	};

	return (
		<div>
			<TextField
				ref={inputRef}
				variant="outlined"
				value={selectedValue}
				onClick={handleClick}
				placeholder={placeholder}
				fullWidth
				sx={{
					...inputDropdown.textField,
					...(selectedValue && inputDropdown.selectedTextField),
					height: '40px',
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<ArrowForwardIos
								style={{
									...inputDropdown.arrowIcon,
									transform: inputDropdown.arrowIcon.transform(open),
								}}
							/>
						</InputAdornment>
					),
				}}
			/>
			<Menu
				elevation={0}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				sx={{
					'& .MuiPaper-root': {
						minWidth: menuWidth,
					},
				}}
			>
				{dropdownItems.map((item, index) => (
					<MenuItem
						disableRipple
						key={index}
						onClick={() => {
							handleSelect(item?.label);
							handleSelectFilter(item?.value);
						}}
					>
						{item?.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}

export default InputDropdown;
