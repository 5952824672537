import {
	IconBox,
	IconBox2,
	IconCardCoin,
	IconClipboardText,
	IconClock,
	IconCube,
	IconCubeScan,
	IconElement3,
	IconHomeHashtag,
	IconMessageText,
	IconNote2,
	IconPeople,
	IconProfile2user,
	IconProfileCircle,
	IconSetting,
	IconSetting2,
	IconShieldSearch,
	IconTag,
	IconTag2,
	IconTagUser,
	IconTruckTime,
	IconUserOctagon,
	IconUserSquare,
	IconUserTag,
	IconCar,
} from '../../../../../assets/Icons';

const color = '#939DAC';
const size = '24px';

export const TERMINAL_ROUTE_PERMISSIONS = {
	cargos: 'cargo_storage_module',
	repack: 'repacking_module',
	repair: 'repair_module',
}

export const menuConfigEmpl = [
	{ path: 'main', label: 'Главная', icon: <IconHomeHashtag color={color} size={size} /> },
	{ path: 'summary', label: 'Отчетности', icon: <IconClipboardText color={color} size={size} /> },
	{ path: 'containers', label: 'Контейнеры', icon: <IconCubeScan color={color} size={size} /> },
	{ path: 'cargos', label: 'Грузы', icon: <IconBox color={color} size={size} /> },
	{ path: 'orders', label: 'Заявки', icon: <IconMessageText color={color} size={size} /> },
	{
		label: 'Пропуска',
		icon: <IconShieldSearch color={color} size={size} />,
		subMenu: [
			{ path: 'entries', label: 'Пропуска', icon: <IconUserSquare color={color} size={size} /> },

			{ path: 'historyEntries', label: 'История въездов', icon: <IconTruckTime color={color} size={size} /> },
		],
	},
	{ path: 'inspection', label: 'Осмотр', icon: <IconNote2 color={color} size={size} /> },
	{
		label: 'Услуги',
		icon: <IconCardCoin color={color} size={size} />,
		subMenu: [
			{ path: 'storage', label: 'Хранение', icon: <IconCube color={color} size={size} /> },

			{ path: 'repair', label: 'Ремонт', icon: <IconSetting color={color} size={size} /> },
			{
				path: 'repack',
				label: 'Перетарка',
				icon: <IconBox2 color={color} size={size} />,
			},
			{ path: 'repairTasks', label: 'Тарифы на ремонт', icon: <IconTag2 color={color} size={size} /> },
			{ path: 'tariffs', label: 'Тарифы и ставки', icon: <IconTag color={color} size={size} /> },
		],
	},
	{ path: 'clients', label: 'Клиенты', icon: <IconProfileCircle color={color} size={size} /> },

	{
		label: 'Перевозчики',
		icon: <IconPeople color={color} size={size} />,
		subMenu: [
			{ path: 'transporters', label: 'Перевозчики', icon: <IconTagUser color={color} size={size} /> },
			{
				path: 'vehicles',
				label: 'Автомобили',
				icon: <IconCar color={color} size={size} />,
			},
		],
	},
	{
		label: 'Пользователи',
		icon: <IconUserOctagon color={color} size={size} />,
		subMenu: [
			{ path: 'empls', label: 'Сотрудники', icon: <IconProfile2user color={color} size={size} /> },
			{
				path: 'createJob',
				label: 'Должности',
				icon: <IconUserTag color={color} size={size} />,
			},
			{ path: 'tableLogs', label: 'История действий', icon: <IconClock color={color} size={size} /> },
		],
	},
	{
		label: 'Настройки',
		icon: <IconSetting2 color={color} size={size} />,
		subMenu: [
			{
				path: 'terminals',
				label: 'Терминалы',
				icon: <IconElement3 color={color} size={size} />,
			},
			{
				path: 'legal',
				label: 'Юридические лица',
				icon: <IconPeople color={color} size={size} />,
			},
		],
	},
];

export const menuConfigClient = [
	{ path: 'main', label: 'Главная', icon: <IconHomeHashtag color={color} size={size} /> },
	{ path: 'summary', label: 'Отчетности', icon: <IconClipboardText color={color} size={size} /> },
	{ path: 'containers', label: 'Контейнеры', icon: <IconCubeScan color={color} size={size} /> },
	{ path: 'cargos', label: 'Грузы', icon: <IconBox color={color} size={size} /> },
	{ path: 'orders', label: 'Заявки', icon: <IconMessageText color={color} size={size} /> },
	{
		label: 'Пропуска',
		icon: <IconShieldSearch color={color} size={size} />,
		subMenu: [
			{ path: 'entries', label: 'Пропуска', icon: <IconUserSquare color={color} size={size} /> },
			{ path: 'historyEntries', label: 'История въездов', icon: <IconTruckTime color={color} size={size} /> },
		],
	},
	{ path: 'inspection', label: 'Осмотр', icon: <IconNote2 color={color} size={size} /> },
	{
		label: 'Услуги',
		icon: <IconCardCoin color={color} size={size} />,
		subMenu: [
			{ path: 'storage', label: 'Хранение', icon: <IconCube color={color} size={size} /> },
			{ path: 'repair', label: 'Ремонт', icon: <IconSetting color={color} size={size} /> },
			{ path: 'repack', label: 'Перетарка', icon: <IconBox2 color={color} size={size} /> },
			{ path: 'repairTasks', label: 'Тарифы на ремонт', icon: <IconTag2 color={color} size={size} /> },
			{ path: 'tariffs', label: 'Тарифы и ставки', icon: <IconTag color={color} size={size} /> },
		],
	},
	{
		label: 'Перевозчики',
		icon: <IconPeople color={color} size={size} />,
		subMenu: [
			{ path: 'transporters', label: 'Перевозчики', icon: <IconTagUser color={color} size={size} /> },
			{ path: 'vehicles', label: 'Автомобили', icon: <IconCar color={color} size={size} /> },
		],
	},
	{
		label: 'Пользователи',
		icon: <IconUserOctagon color={color} size={size} />,
		subMenu: [
			{ path: 'empls', label: 'Сотрудники', icon: <IconProfile2user color={color} size={size} /> },
			{ path: 'tableLogs', label: 'История действий', icon: <IconClock color={color} size={size} /> },
		],
	},
	{
		label: 'Настройки',
		icon: <IconSetting2 color={color} size={size} />,
		subMenu: [{ path: 'company', label: 'Предприятие', icon: <IconElement3 color={color} size={size} /> }],
	},
];
