import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import api from '../../api.js';
import { getValidatedFields } from '../../../shared/api/getValidatedFields.js';

export const service = api.injectEndpoints({
	endpoints: (builder) => ({
		getServiceList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `api/service/list/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getRepairTasks: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/service/repair_tasks/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getServiceOrders: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/service-order/repair/orders/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getServiceOrdersById: builder.query({
			query: ({ id }) => {
				return {
					url: `/api/service-order/repair/orders/${id}/`,
					method: 'GET',
				};
			},
		}),
		getRepairGroupById: builder.query({
			query: ({ id }) => {
				return {
					url: `/api/service-order/repair/orders/repair-group/${id}/`,
					method: 'GET',
				};
			},
		}),
		getStorageOrdersList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/service-order/storage/orders/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getRepackingOrdersList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `api/service-order/repacking/orders/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		createServiceOrder: builder.mutation({
			query: ({ ...payload }) => {
				return {
					url: `/api/service-order/repair/orders/create/`,
					method: 'POST',
					body: {
						...payload,
					},
				};
			},
		}),
		exportExcelRepairOrder: builder.mutation({
			query: ({ id }) => {
				return {
					url: `/api/service-order/repair/orders/${id}/export_excel/`,
					method: 'GET',
					responseHandler: async (response) => {
						if (response.ok) {
							const blob = await response.blob();
							const link = document.createElement('a');
							link.href = window.URL.createObjectURL(blob);
							link.download = `Акт_ремонтных_работ_${id}.xlsx`;
							link.click();
						}
						return response;
					},
				};
			},
		}),
		// Создание услуги для ремонтной работы
		createRepairTask: builder.mutation({
			query: ({ repair_group_pk, ...payload }) => {
				return {
					url: `/api/service-order/repair/repair-task/${repair_group_pk}/create/`,
					method: 'POST',
					body: {
						repair_task: payload.repair_task,
					},
				};
			},
		}),
		// Удалить услугу из ремонтной работы
		deleteRepairTask: builder.mutation({
			query: ({ repair_task_pk }) => {
				return {
					url: `/api/service-order/repair/repair-task/${repair_task_pk}/delete/`,
					method: 'DELETE',
				};
			},
		}),
		updateRepairTask: builder.mutation({
			query: ({ id, ...payload }) => {
				console.log(id, payload);
				const validatedData = getValidatedFields(payload);
				console.log(validatedData);
				return {
					url: `/api/service-order/repair/repair-task/${id}/update/`,
					method: 'PUT',
					body: {
						...validatedData,
					},
				};
			},
		}),
		updateRepairGroup: builder.mutation({
			query: ({ id, ...payload }) => {
				console.log(id, payload);
				const validatedData = getValidatedFields(payload);
				console.log(validatedData);
				return {
					url: `/api/service-order/repair/orders/repair-group/${id}/update/`,
					method: 'PUT',
					body: {
						...validatedData,
					},
				};
			},
		}),
		updateRepairOrder: builder.mutation({
			query: ({ id, ...payload }) => {
				console.log(id, payload);
				const validatedData = getValidatedFields(payload);
				console.log(validatedData);
				return {
					url: `/api/service-order/repair/orders/${id}/update/`,
					method: 'PUT',
					body: {
						...validatedData,
					},
				};
			},
		}),
		// Создание тарифа в Таблице Тарифы и ставки
		createTariff: builder.mutation({
			query: ({ ...payload }) => {
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/service/create/`,
					method: 'POST',
					body: {
						...validatedData,
					},
				};
			},
		}),
		// Редактирование тарифов в Таблице Тарифы и ставки
		updateTariffGroup: builder.mutation({
			query: ({ ...payload }) => {
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/service/batch-update/`,
					method: 'PUT',
					body: {
						...validatedData,
					},
				};
			},
		}),
		// Получение одного тарифа
		getTariffById: builder.query({
			query: (params) => {
				let queryParams = { ...params }; 
				const { id } = params;
				delete queryParams.uniq; 

				return {
					url: `/api/service/list/?service_id=${id}&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		// Получение одного тарифа на ремонт
		getRepairTasksById: builder.query({
			query: ({ id }) => {
				return {
					url: `/api/service/repair_tasks/?task_id=${id}`,
					method: 'GET',
				};
			},
		}),
		// Получение компонентов для таблица Тарифы на ремонт
		getRepairComponents: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				return {
					url: `/api/service/repair-component/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		// Создание тарифа в Таблице Тарифы на ремонт
		createRepairTariff: builder.mutation({
			query: ({ ...payload }) => {
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/service/repair-tasks/create/`,
					method: 'POST',
					body: {
						...validatedData,
					},
				};
			},
		}),
		// Редактирование тарифов в Таблице Тарифы на ремонт
		updateRepairTariffGroup: builder.mutation({
			query: ({ ...payload }) => {
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/service/repair-tasks/batch-update/`,
					method: 'PUT',
					body: {
						...validatedData,
					},
				};
			},
		}),

		// Хранение акт detail
		getStorageById: builder.query({
			query: ({ id }) => {
				return {
					url: `/api/service-order/storage/orders/?id=${id}`,
					method: 'GET',
				};
			},
		}),
		// Создание акта хранения в Таблице Хранение
		createStorage: builder.mutation({
			query: ({ ...payload }) => {
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/service-order/storage/orders/create/`,
					method: 'POST',
					body: {
						...validatedData,
					},
				};
			},
		}),
		// Редактирование акта хранения в Таблице Хранение
		updateStorage: builder.mutation({
			query: ({ storage_order_pk, ...payload }) => {
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/service-order/storage/orders/${storage_order_pk}/update/`,
					method: 'PUT',
					body: {
						...validatedData,
					},
				};
			},
		}),
	}),
});

export const {
	useGetServiceListQuery,
	useLazyGetServiceListQuery,
	useLazyGetRepairTasksQuery,
	useGetRepairTasksQuery,
	useLazyGetServiceOrdersQuery,
	useLazyGetStorageOrdersListQuery,
	useLazyGetRepackingOrdersListQuery,
	useCreateServiceOrderMutation,
	useCreateRepairTaskMutation,
	useGetServiceOrdersByIdQuery,
	useLazyGetServiceOrdersByIdQuery,
	useGetRepairGroupByIdQuery,
	useLazyGetRepairGroupByIdQuery,
	useDeleteRepairTaskMutation,
	useUpdateRepairGroupMutation,
	useUpdateRepairOrderMutation,
	useUpdateRepairTaskMutation,
	useCreateTariffMutation,
	useUpdateTariffGroupMutation,
	useGetTariffByIdQuery,
	useGetRepairTasksByIdQuery,
	useLazyGetRepairComponentsQuery,
	useCreateRepairTariffMutation,
	useUpdateRepairTariffGroupMutation,
	useCreateStorageMutation,
	useUpdateStorageMutation,
	useGetStorageByIdQuery,
	useExportExcelRepairOrderMutation,
} = service;
