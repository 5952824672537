import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../../../../newComponents/Header/Header";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PageTabs } from "../../../../../shared/ui";
import styles from './CreateOrderPage.module.css';
import { ORDERS_LIST_URL } from "../../../../../shared/orders/pageUrls";
import { ChooseTerminalStep } from "./components/ChooseTerminalStep/ChooseTerminalStep";
import { useLazyGetOrderQuery } from "../../../../../store/newApi/orders/orders";
import { ChooseContainerStep } from "./components/ChooseContainerStep/ChooseContainerStep";
import { ORDER_NAME, ORDER_TYPE, ORDER_TYPE_BY_ID } from "../../../../../shared/orders/constants";
import { OrderCommentStep } from "./components/OrderCommentStep/OrderCommentStep";
import { Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";

export const CreateOrderPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { terminalId, orderId } = useParams();
    const isDesktop = useMediaQuery({ minWidth: 1279.9 }); 

    const [params] = useSearchParams();
    const step = params.get('step');
    const orderType = params.get('orderType');

    const [currentStep, setCurrentStep] = useState(0);
    const [isImportOpen, setImportOpen] = useState(false);

    const [fetchOrder, { data: orderData, isFetching: isFetchingOrder }] = useLazyGetOrderQuery();

    const isEditMode = useMemo(() => !!orderData, [orderData]);
    const isDelivery = useMemo(() => orderData?.type === ORDER_TYPE[ORDER_NAME.DELIVERY].id, [orderData])

    useEffect(() => {
        if (terminalId && orderId)
            fetchOrder({terminalId, orderId})
    }, [terminalId, orderId, fetchOrder]);

    useEffect(() => {
        if (step)
            setCurrentStep(+step);
    }, [step]);

    const handleChangeStep = useCallback((step: number) => {
        navigate(`${location.pathname}?step=${step}`)
    }, [navigate, location])

    const isChooseContainerStep = useMemo(() => currentStep === 1, [currentStep])

    const renderStep = useCallback(() => {
        if (currentStep === 0)
            return <ChooseTerminalStep
                refetchOrder={() => fetchOrder({ terminalId, orderId })} 
                orderId={orderData?.id}
                onBack={() => navigate(ORDERS_LIST_URL)}
                orderType={orderType}
                defaultOrderData={orderData}
            />
        if (currentStep === 1)
            return <ChooseContainerStep
                orderId={+orderId}
                terminalId={+terminalId}
                clientId={orderData?.client?.id}
                isFetchingOrder={isFetchingOrder}
                disabledCreateNewContainer={isDelivery}
                isDelivery={isDelivery}
                onCancel={() => navigate(ORDERS_LIST_URL)}
                onBack={() => handleChangeStep(0)}
                onNext={() => navigate(ORDERS_LIST_URL)}
                isImportOpen={isImportOpen}
                toggleIsOpen={setImportOpen}
                totalEtaPrice={orderData?.total_eta_storage_price}
                enabledStoragePrice={!!orderData?.client?.can_see_storage_price}
                refetchOrder={() => fetchOrder({ terminalId, orderId })}
            />
        if (currentStep === 2)
            return <OrderCommentStep
                onCancel={() => navigate(ORDERS_LIST_URL)}
                onBack={() => handleChangeStep(1)}
                defaultComment={orderData?.comment}
                orderId={orderData?.id}
                terminalId={orderData?.terminal?.id}
            />
        return null;
    }, [currentStep, orderType, orderData, orderId, terminalId, isFetchingOrder, isDelivery, isImportOpen, navigate, handleChangeStep])

    const getHeaderTitle = useCallback(() => {
        if (orderType === '1')
            return `Создание заявки на прием контейнера`
        if (orderType === '2')
            return `Создание заявки на выдачу контейнера`
        return `Обновление заявки ${!!orderData ? `№ ${orderData?.id} на ${ORDER_TYPE_BY_ID[orderData?.type]}` : ''}`;
    }, [orderType, orderData]);

    const showImportBtn = useMemo(() => !isDelivery && isChooseContainerStep, [isDelivery, isChooseContainerStep]);

    return <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
        {/* @ts-ignore */}
        <Header
            text={getHeaderTitle()}
            btnDesktop={[
                showImportBtn && <Button variant="contained" color="primary" size="medium" onClick={() => setImportOpen(true)}>
                    Импорт контейнеров
                </Button>
            ]}
            btnMobile={[
                showImportBtn && <Button variant="contained" color="primary" size="small" onClick={() => setImportOpen(true)}>
                    Импорт контейнеров
                </Button>
            ]}
        />
        <div className={styles.content}>
            <PageTabs
                tabs={[
                    { title: 'Информация', step: 0 },
                    { title: 'Контейнеры', step: 1, disabled: !isEditMode },
                    { title: 'Комментарий', step: 2, disabled: !isEditMode },
                ]}
                onTabClick={handleChangeStep}
                currentStep={currentStep}
            />
            <div className={styles.steps}>
                {renderStep()}
            </div>
        </div>
    </div>
}